import anime from 'animejs';

export const fullAnimation = () => {
    const bigDarkRectangle = anime.timeline({
        targets: '.hero-figure-box-05',
        autoplay: false,
        begin: function () {
            smallDarkRectangles.play();
            colouredRectangles.play();
        },
    })
        .add({
            duration: 400,
            easing: 'easeInOutExpo',
            scaleX: [0.05, 0.05],
            scaleY: [0, 1],
            opacity: [0, 1],
            perspective: '500px',
        })
        .add({
            duration: 400,
            easing: 'easeInOutExpo',
            scaleX: 1,
        })
        .add({
            duration: 800,
            rotateY: '-15deg',
            rotateX: '8deg',
            rotateZ: '-1deg',
        });

    const smallDarkRectangles = anime.timeline({
        targets: '.hero-figure-box-06',
        autoplay: false,
    })
        .add({
            duration: 400,
            easing: 'easeInOutExpo',
            scaleX: [0.05, 0.05],
            scaleY: [0, 1],
            perspective: '500px',
        })
        .add({
            duration: 400,
            easing: 'easeInOutExpo',
            scaleX: 1,
        })
        .add({
            duration: 800,
            rotateZ: '20deg',
        });

    const colouredRectangles = anime.timeline({
        targets: '.hero-figure-box-01, .hero-figure-box-02, .hero-figure-box-03, .hero-figure-box-04, .hero-figure-box-07, .hero-figure-box-08, .hero-figure-box-09, .hero-figure-box-10',
        autoplay: false,
    })
        .add({
            duration: anime.random(600, 800),
            delay: anime.random(600, 800),
            rotate: [
                anime.random(-360, 360),
                function (el) {
                    return el.getAttribute('data-rotation');
                },
            ],
            scale: [0.7, 1],
            opacity: [0, 1],
            easing: 'easeInOutExpo',
        });

    function init() {
        bigDarkRectangle.play();
    }

    function restart() {
        bigDarkRectangle.restart();
        smallDarkRectangles.restart();
        colouredRectangles.restart();
    }

    return {
        init,
        restart,
    };
};

export const fullAnimationRemove = () => {
    const bigDarkRectangle = anime.timeline({
        targets: '.hero-figure-box-05',
        autoplay: false,
        begin: function () {
            smallDarkRectangles.play();
            colouredRectangles.play();
        },
    })
        .add({
            duration: 800,
            rotateY: '0deg',
            rotateX: '0deg',
            rotateZ: '0deg',
        })
        .add({
            duration: 400,
            easing: 'easeInOutExpo',
            scaleX: 0,
        })
        .add({
            duration: 400,
            easing: 'easeInOutExpo',
            scaleX: [0.05, 0.05],
            scaleY: [1, 0],
            opacity: [1, 0],
            perspective: '0px',
        });

    const smallDarkRectangles = anime.timeline({
        targets: '.hero-figure-box-06',
        autoplay: false,
    })
        .add({
            duration: 800,
            rotateZ: '0deg',
        })
        .add({
            duration: 400,
            easing: 'easeInOutExpo',
            scaleX: 0,
        })
        .add({
            duration: 400,
            easing: 'easeInOutExpo',
            scaleX: [0.05, 0.05],
            scaleY: [0, 0],
            perspective: '0px',
        });

    const colouredRectangles = anime.timeline({
        targets: '.hero-figure-box-01, .hero-figure-box-02, .hero-figure-box-03, .hero-figure-box-04, .hero-figure-box-07, .hero-figure-box-08, .hero-figure-box-09, .hero-figure-box-10',
        autoplay: false,
    })
        .add({
            duration: anime.random(600, 800),
            delay: anime.random(600, 800),
            rotate: [
                function (el) {
                    return el.getAttribute('data-rotation');
                }, anime.random(-360, 360)
            ],
            scale: [1, 0.7],
            opacity: [1, 0],
            easing: 'easeInOutExpo',
        });

    function init() {
        bigDarkRectangle.play();
    }

    return {
        init,
    };
};
