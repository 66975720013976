export const SymbolTitreExpIcon = (props = {
	classStyle: '',
	idStyle: '',
	style: '',
}) => (
	<>
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="2000px" height="1025px" viewBox="0 0 2000 1025" className={props.classStyle} id={props.idStyle} style={props.style}><g>
			<path d="
M1921.221,415.024c-8.945,5.771-16.604,11.266-24.76,15.879c-47.22,26.709-97.622,45.098-150.539,56.68
		c-40.323,8.825-81.11,9.61-122.192,7.517c-91.021-4.64-176.329-30.187-258.764-67.772
		c-95.803-43.681-189.595-91.353-282.852-140.127c-76.051-39.774-152.739-78.195-232.077-111.079
		c-57.915-24.005-116.862-44.916-178.11-58.888c-42.854-9.776-86.13-15.096-130.163-13.479
		c-72.983,2.681-143.518,16.273-210.351,46.659c-66.548,30.256-123.263,73.583-171.112,128.682
		c-36.635,42.187-64.804,89.279-82.18,142.571c-9.412,28.866-15.254,58.427-17.852,88.703
		c-5.903,68.81,3.625,135.052,32.521,198.076c21.229,46.304,51.289,86.368,87.957,121.474
		C242.688,889.22,314.34,932.403,396.22,958.29c32.866,10.391,66.458,17.407,100.673,21.717
		c34.133,4.299,68.378,5.655,102.707,4.683c40.621-1.151,81-5,121.133-11.599c129.378-21.271,250.37-65.479,363.168-132.24
		c0.563-0.333,1.159-0.614,1.757-0.881" className="logo_symbol2"></path> <path d="
		M1757.576,636.188c-50.259,24.711-102.293,44.582-157.028,56.947c-32.424,7.323-65.17,8.522-98.271,5.88
		c-95.56-7.629-185.555-35.95-273.277-72.805c-70.534-29.633-137.492-66.202-203.333-104.98
		c-61.652-36.312-124.402-70.593-189.771-99.882c-49.586-22.217-100.723-39.69-154.07-50.316
		c-39.143-7.796-78.682-11.724-118.614-10.086c-63.37,2.6-124.335,16.981-183.481,39.211c-23.929,8.994-47.289,19.521-70.812,29.573
		" className="logo_symbol2"></path> <path d="
		M967.541,55.732c12.62-3.917,25.186-8.024,37.872-11.716c34.646-10.081,69.75-18.166,105.537-22.97
		c83.675-11.23,163.003,2.267,237.525,42.361c55.807,30.025,107.98,65.787,159.972,101.814
		c45.725,31.686,92.232,62.081,142.579,86.069c32.948,15.699,67.031,28.059,103.07,34.855c40.912,7.716,80.741,3.761,118.895-12.057
		c27.91-11.57,54.804-25.595,82.148-38.529c1.952-0.923,4.003-1.694,5.817-2.839" className="logo_symbol2"></path></g></svg>
	</>
)

export const TitreIcon = (props = {
	classStyle: '',
	idStyle: '',
	style: '',
}) => (
	<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="1280.000000pt" height="768.000000pt" viewBox="0 0 1280.000000 768.000000" preserveAspectRatio="xMidYMid meet" className={props.classStyle} id={props.idStyle} style={props.style}>
		<g transform="translate(0.000000,768.000000) scale(0.100000,-0.100000)"><path d="M6236 6161 c-212 -70 -336 -290 -353 -626 -5 -96 -2 -124 20 -215 30
    -122 82 -271 200 -570 108 -275 127 -336 171 -555 47 -235 106 -567 106 -593
    0 -12 4 -23 8 -26 5 -3 25 93 46 212 94 541 116 628 259 1012 77 210 139 398
    164 500 26 107 23 320 -5 455 -42 198 -144 343 -279 396 -83 32 -254 37 -337
    10z" className="logo_symbol symbol_svg"></path> <path d="M4673 5231 c-223 -77 -297 -319 -153 -501 70 -88 216 -163 525 -270
    204 -70 318 -124 441 -206 97 -64 240 -196 316 -292 l52 -65 14 34 c48 116
    -54 421 -232 694 -121 185 -280 363 -421 470 -70 53 -219 128 -287 144 -71 17
    -195 13 -255 -8z" className="logo_symbol symbol_svg"></path> <path d="M7763 5226 c-143 -47 -281 -143 -430 -299 -228 -238 -429 -595 -474
    -840 -11 -63 -6 -150 11 -177 8 -13 12 -11 27 10 41 59 138 163 198 214 144
    122 241 174 535 290 375 147 454 186 548 271 74 67 107 139 107 235 0 58 -5
    86 -23 121 -36 74 -87 125 -160 161 -60 30 -76 33 -167 35 -83 3 -112 -1 -172
    -21z" className="logo_symbol symbol_svg"></path> <path d="M2570 5010 c-238 -31 -447 -130 -574 -273 -143 -159 -212 -399 -186
    -640 37 -338 228 -568 555 -670 125 -39 218 -50 367 -44 142 6 216 21 421 84
    80 25 121 33 128 26 15 -15 -717 -665 -1032 -915 -484 -385 -771 -525 -1143
    -558 -295 -27 -538 43 -697 200 -122 120 -164 223 -164 400 0 132 16 194 72
    277 38 56 74 88 140 122 96 48 276 67 351 35 40 -16 92 -61 92 -78 0 -6 -23
    -23 -50 -38 -66 -37 -93 -89 -94 -178 -1 -107 44 -170 160 -222 66 -30 196
    -32 269 -4 115 44 182 115 217 230 59 194 -46 392 -264 496 -105 51 -195 70
    -329 70 -396 0 -685 -172 -783 -467 -24 -71 -26 -92 -26 -239 0 -142 3 -168
    24 -230 100 -296 324 -481 666 -549 111 -22 336 -30 460 -16 367 42 704 193
    1105 496 181 137 256 201 630 540 160 145 316 285 348 311 79 65 88 53 48 -65
    -67 -199 -85 -461 -43 -621 62 -233 210 -431 403 -539 169 -94 412 -131 639
    -96 120 18 186 38 278 83 156 77 272 191 343 337 62 128 70 210 32 344 -29
    106 -81 191 -167 276 -125 125 -249 175 -431 175 -200 0 -348 -71 -420 -201
    -27 -49 -30 -63 -30 -144 0 -79 4 -97 28 -148 46 -93 108 -127 232 -127 97 0
    167 34 209 103 25 41 30 61 34 132 3 48 0 97 -7 116 l-11 32 50 -5 c70 -7 171
    -59 219 -114 112 -128 114 -337 3 -476 -74 -94 -202 -171 -340 -205 -96 -24
    -283 -21 -387 6 -288 74 -441 270 -469 601 -7 81 8 246 30 337 86 342 330 568
    728 673 190 50 316 65 561 64 200 0 231 -2 345 -27 644 -137 1018 -493 1071
    -1018 25 -255 -39 -481 -167 -587 -151 -126 -428 -148 -562 -45 -76 58 -105
    196 -59 278 24 42 106 105 138 105 14 0 19 -7 19 -27 0 -48 45 -109 95 -128
    83 -32 165 6 210 100 23 46 27 64 21 102 -12 91 -83 171 -180 203 -24 8 -70
    11 -121 8 -205 -11 -392 -176 -411 -364 -25 -238 121 -428 368 -480 87 -19
    267 -13 368 11 214 52 383 201 456 402 15 41 32 72 37 70 6 -2 18 -26 28 -55
    54 -159 167 -291 310 -361 128 -62 191 -76 354 -76 106 0 154 5 199 19 235 71
    348 224 334 452 -6 98 -34 161 -103 230 -110 110 -299 161 -442 119 -29 -9
    -69 -32 -96 -56 -89 -77 -95 -183 -16 -273 70 -79 150 -85 219 -16 23 23 43
    56 50 79 12 47 17 48 68 22 73 -38 116 -107 116 -191 1 -109 -80 -194 -214
    -224 -230 -53 -443 41 -536 237 -46 98 -64 188 -64 333 0 304 96 549 296 748
    183 183 467 324 786 388 98 20 142 23 328 22 501 -1 857 -117 1082 -352 175
    -184 262 -440 244 -727 -11 -193 -57 -313 -162 -425 -107 -113 -241 -171 -442
    -191 -275 -28 -546 98 -640 299 -24 51 -27 69 -27 162 0 92 3 112 27 161 48
    103 146 176 252 187 l48 5 -7 -42 c-8 -51 11 -174 36 -222 24 -48 80 -95 126
    -108 53 -15 158 -14 196 2 94 40 148 128 155 254 4 74 2 88 -22 137 -67 137
    -225 216 -431 216 -107 0 -184 -18 -280 -64 -245 -120 -391 -429 -315 -667 75
    -233 298 -413 590 -476 125 -27 364 -24 483 5 310 77 528 291 618 607 54 190
    29 456 -63 658 -62 135 -21 111 282 -163 460 -416 633 -562 840 -710 307 -219
    611 -359 905 -417 126 -25 436 -25 555 0 331 69 543 222 664 478 54 113 71
    188 70 309 -1 305 -170 540 -461 641 -157 54 -417 76 -548 45 -268 -63 -450
    -281 -416 -496 21 -129 92 -224 209 -278 48 -22 71 -27 147 -27 114 0 181 22
    240 79 106 102 82 281 -47 347 -21 11 -38 23 -38 28 0 19 43 61 83 80 84 41
    262 22 379 -41 133 -71 198 -198 198 -388 0 -161 -49 -288 -153 -392 -145
    -145 -330 -210 -597 -210 -500 0 -863 199 -1745 961 -433 373 -576 504 -566
    514 7 7 56 -3 158 -31 315 -89 455 -102 656 -61 202 41 338 110 467 239 98 97
    156 197 192 327 29 106 31 344 4 448 -43 169 -148 326 -280 419 -258 183 -650
    236 -936 125 -263 -101 -450 -346 -424 -553 24 -190 148 -302 344 -314 120 -7
    189 14 251 76 54 55 74 110 66 188 -13 130 -98 202 -241 207 -34 1 -56 7 -56
    13 0 17 68 105 107 138 139 118 447 111 671 -15 84 -47 193 -162 231 -243 115
    -239 56 -555 -132 -721 -60 -53 -186 -118 -272 -140 -183 -48 -421 -20 -775
    90 -281 88 -759 181 -1150 226 -245 27 -508 30 -662 5 -475 -75 -906 -315
    -1128 -627 -48 -69 -127 -217 -158 -299 l-14 -35 -10 25 c-87 203 -198 372
    -324 490 -360 336 -904 520 -1422 482 -390 -30 -980 -145 -1382 -271 -254 -79
    -470 -117 -615 -108 -184 12 -317 69 -436 187 -122 121 -170 243 -170 430 -1
    179 46 295 166 415 117 118 269 182 451 192 193 11 332 -45 416 -167 20 -30
    38 -57 38 -60 0 -3 -30 -7 -67 -8 -127 -3 -208 -65 -233 -177 -37 -168 83
    -295 280 -295 174 0 300 76 352 213 105 276 -122 587 -498 683 -107 27 -271
    38 -374 24z" className="logo_symbol symbol_svg"></path></g></svg>
)

export const LogoArchiIcon = (props = {
	classStyle: '',
	idStyle: '',
	style: '',
}) => (
	<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="513px" height="500px" viewBox="0 0 513 500"  className={props.classStyle} id={props.idStyle} style={props.style}><g><path d="
		M350.271,403.119c-0.671,0-1.342,0-2.012,0c-88.441,0.001-176.883,0.002-265.325,0.002c-3.514,0-7.03,0.09-10.542-0.022
		c-10.247-0.326-19.177-4.266-26.289-12.599c-3.84-4.499-6.643-9.78-7.497-16.056c-0.405-2.973-0.747-5.993-0.748-8.992
		c-0.038-96.89,0.006-193.778-0.072-290.667c-0.011-13.864,5.124-24.286,15.575-31.527c4.03-2.793,8.395-4.844,13.193-5.36
		c3.235-0.348,6.484-0.701,9.73-0.731c10.046-0.094,20.093,0,30.14-0.071c1.525-0.011,1.913,0.514,1.908,2.163
		c-0.048,17.25-0.029,34.5-0.026,51.751c0,2.421,0.006,2.426,2.182,2.426c115.958,0.001,231.918,0.002,347.876,0.003
		c4.604,0,9.206-0.006,13.809,0.003c2.811,0.006,2.592-0.312,2.593,2.806c0.005,22.652,0.006,45.303,0.005,67.955
		c-0.001,78.702-0.003,157.403-0.006,236.104c0,0.386-0.032,0.775,0.006,1.156c0.132,1.327-0.427,1.699-1.56,1.686
		c-4.503-0.055-9.007-0.01-13.511-0.052c-0.55-0.005-1.215-0.148-1.629-0.51c-4.782-4.166-9.527-8.387-14.278-12.598
		c-0.144-0.127-0.248-0.31-0.565-0.716c0.778-0.09,1.335-0.207,1.892-0.209c4.504-0.017,9.008-0.011,13.512-0.014
		c2.04-0.001,2.044-0.004,2.044-2.236c0.001-40.563,0.002-81.126,0.002-121.689c-0.001-51.861-0.009-103.723,0.024-155.584
		c0.001-1.554-0.322-2.066-1.793-2.066c-116.157,0.041-232.313,0.041-348.47,0.044c-2.129,0-2.132,0.001-2.132,2.305
		c-0.002,77.985-0.003,155.97-0.004,233.955c0,0.275,0.001,0.551,0,0.827c-0.004,2.204-0.005,2.21-1.95,2.21
		c-11.037,0.002-22.073-0.021-33.11,0.008c-8.225,0.021-14.757,3.73-19.049,11.604c-2.409,4.419-2.938,9.287-1.659,14.42
		c1.969,7.906,6.926,12.467,13.504,15.032c2.364,0.922,5.053,1.122,7.594,1.124c56.223,0.061,112.445,0.047,168.667,0.048
		c30.832,0,61.665,0.011,92.497-0.035c1.486-0.002,2.613,0.413,3.701,1.537c3.765,3.888,7.599,7.69,11.404,11.528
		c0.171,0.173,0.321,0.371,0.481,0.558C350.347,402.799,350.309,402.959,350.271,403.119z" className="logo_exper archi_svg"></path> <path d="
		M357.286,122.167c-4.305,4.317-8.608,8.634-13.23,13.271c0.883,0.107,1.34,0.209,1.798,0.21c5.197,0.018,10.394,0.056,15.59-0.014
		c0.774-0.01,1.684-0.344,2.29-0.871c3.86-3.355,7.923-6.359,12.472-8.38c2.907-1.291,5.958-2.207,8.98-3.147
		c4.801-1.492,9.728-1.916,14.683-1.591c4.186,0.274,8.284,1.121,12.353,2.373c7.31,2.25,13.772,6.165,19.471,11.64
		c4.749,4.564,8.553,10.029,11.141,16.406c2.994,7.374,4.424,15.158,3.575,23.246c-0.875,8.35-3.55,15.998-8.103,22.775
		c-4.449,6.625-10.021,11.81-16.627,15.502c-3.005,1.68-6.267,2.822-9.466,4.03c-5.629,2.127-11.461,2.722-17.373,2.453
		c-1.025-0.047-2.053-0.252-3.061-0.486c-0.944-0.218-1.44,0.151-1.545,1.18c-0.05,0.49-0.024,0.991-0.024,1.487
		c-0.001,7.936-0.001,15.873-0.002,23.809c0,2.213-0.001,2.22-1.921,2.221c-36.326,0-72.653-0.008-108.98,0.027
		c-1.372,0.002-1.902-0.349-1.894-2.002c0.059-11.739,0.012-23.479,0.079-35.217c0.005-0.923,0.395-2.11,0.984-2.72
		c3.649-3.781,7.398-7.443,11.142-11.109c0.411-0.401,1-0.577,1.506-0.857c0.137,0.606,0.392,1.211,0.393,1.817
		c0.022,11.298,0.046,22.597-0.019,33.894c-0.01,1.675,0.406,2.136,1.914,2.133c26.973-0.051,53.945-0.052,80.919-0.006
		c1.479,0.002,1.828-0.536,1.802-2.083c-0.08-4.738-0.147-9.484,0.025-14.216c0.076-2.069-0.645-3.055-2.195-3.848
		c-6.188-3.164-11.472-7.731-15.878-13.463c-3.363-4.375-5.991-9.263-7.731-14.779c-1.828-5.796-2.751-11.685-2.372-17.786
		c0.251-4.049,0.966-8.028,2.354-11.818c0.628-1.713,1.279-3.416,1.866-5.146c0.504-1.488-0.495-1.352-1.286-1.353
		c-5.593-0.007-11.193,0.194-16.774-0.08c-3.147-0.154-5.412,0.903-7.736,3.273c-11.143,11.359-22.471,22.494-33.71,33.736
		c-4.612,4.613-9.137,9.336-13.755,13.941c-14.635,14.593-29.307,29.139-43.942,43.729c-18.168,18.112-36.318,36.249-54.472,54.38
		c-18.888,18.864-37.771,37.734-56.66,56.597c-0.315,0.314-0.677,0.57-1.461,1.224c0-1.478,0-2.482,0-3.488
		c-0.002-32.627-0.006-65.254-0.006-97.881c0-43.595,0.004-87.189,0.006-130.784c0-2.81,0.003-2.811,2.45-2.811
		c76.711,0.001,153.423,0.002,230.135,0.008c0.722,0,1.444,0.08,2.167,0.122C357.199,121.866,357.242,122.017,357.286,122.167z
		 M164.886,254.686c30.318-30.281,60.338-60.265,90.359-90.248c-0.042-0.143-0.084-0.285-0.126-0.428
		c-0.509-0.063-1.018-0.183-1.526-0.183c-29.091-0.008-58.182,0.003-87.272-0.031c-1.331-0.001-1.664,0.506-1.662,1.918
		c0.031,28.924,0.023,57.849,0.031,86.773C164.689,253.062,164.789,253.636,164.886,254.686z" className="logo_exper archi_svg"></path> <path d="
		M475.214,441.628c-0.28,10.633-4.771,18.556-12.129,24.74c-3.171,2.665-6.764,4.353-10.574,5.618
		c-4.438,1.474-8.948,1.991-13.477,1.295c-7.005-1.077-13.219-4.056-18.602-9.469c-18.502-18.603-37.182-36.984-55.761-55.491
		c-7.61-7.582-15.113-15.297-22.704-22.903c-18.888-18.927-37.798-37.827-56.699-56.738c-1.831-1.832-3.605-3.743-5.511-5.475
		c-1.358-1.233-1.83-2.879-2.32-4.607c-1.02-3.597-2.11-7.169-3.165-10.754c-0.701-2.384-1.388-4.773-2.089-7.156
		c-0.778-2.649-1.587-5.288-2.344-7.945c-0.743-2.604-1.415-5.231-2.162-7.834c-0.656-2.289-1.38-4.554-2.063-6.833
		c-0.746-2.491-1.483-4.985-2.217-7.48c-0.81-2.752-1.647-5.495-2.392-8.269c-0.196-0.733-0.094-1.567-0.129-2.355
		c0.667-0.017,1.369-0.194,1.996-0.025c4.016,1.086,8.019,2.228,12.018,3.39c4.613,1.342,9.209,2.761,13.828,4.077
		c3.621,1.032,7.274,1.918,10.896,2.944c3.618,1.024,7.207,2.183,10.825,3.21c3.575,1.016,7.191,1.857,10.747,2.948
		c1.282,0.394,2.65,1.026,3.625,1.994c16.291,16.164,32.526,32.396,48.771,48.617c18.626,18.598,37.256,37.191,55.872,55.803
		c12.613,12.611,25.261,25.182,37.771,37.919c3.413,3.475,5.702,7.943,6.756,13.036
		C474.511,436.436,474.811,439.045,475.214,441.628z M409.466,433.065c0.47-0.376,0.85-0.623,1.171-0.943
		c7.534-7.512,15.07-15.021,22.586-22.556c1.481-1.484,1.44-1.646-0.081-3.174c-22.889-22.976-45.748-45.989-68.679-68.914
		c-14.292-14.288-28.698-28.434-43.028-42.674c-0.998-0.991-1.714-1.126-2.765-0.069c-7.495,7.537-15.038,15.014-22.554,22.524
		c-1.32,1.318-1.305,1.502-0.012,2.798c14.432,14.464,28.85,28.945,43.305,43.383c16.029,16.01,32.095,31.976,48.141,47.967
		c6.874,6.851,13.736,13.717,20.609,20.567C408.548,432.361,408.994,432.674,409.466,433.065z M419.588,443.076
		c0.436,0.567,0.715,1.021,1.071,1.379c3.216,3.238,6.385,6.541,9.686,9.669c5.151,4.882,11.023,6.489,17.657,4.588
		c5.726-1.641,9.957-5.218,11.983-11.481c1.716-5.307,1.554-10.751-1.861-15.069c-3.664-4.635-7.985-8.623-11.992-12.926
		c-1.03-1.105-1.813-1.011-2.874,0.061c-5.843,5.899-11.757,11.713-17.629,17.577C423.615,438.884,421.65,440.955,419.588,443.076z
		 M288.335,305.361c6.176-6.146,11.986-11.927,17.974-17.884c-0.693-0.544-1.042-0.999-1.469-1.122
		c-2.473-0.715-4.968-1.333-7.447-2.021c-2.57-0.713-5.133-1.458-7.695-2.207c-2.37-0.692-4.72-1.484-7.107-2.086
		c-1.458-0.367-1.929,0.36-1.428,1.933c0.31,0.97,0.673,1.92,0.961,2.897c1.41,4.768,2.788,9.547,4.21,14.31
		C286.895,301.066,287.54,302.919,288.335,305.361z" className="logo_exper archi_svg"></path> <path d="
		M221.048,374.973c-15.782,0-31.563,0.001-47.345-0.001c-1.968,0-1.97-0.006-1.971-2.175c-0.001-3.361,0.04-6.723-0.02-10.082
		c-0.023-1.313,0.302-1.838,1.578-1.837c31.86,0.032,63.72,0.029,95.579,0.002c1.159-0.001,1.567,0.406,1.545,1.71
		c-0.061,3.524-0.078,7.053,0.007,10.576c0.036,1.504-0.475,1.836-1.731,1.833C252.809,374.959,236.929,374.973,221.048,374.973z" className="logo_exper archi_svg"></path> <path d="
		M150.455,374.969c-1.831,0-3.663-0.041-5.492,0.017c-0.987,0.031-1.446-0.294-1.433-1.484c0.042-3.746,0.032-7.493,0.006-11.24
		c-0.007-0.996,0.352-1.365,1.234-1.359c3.859,0.026,7.719,0.022,11.579-0.001c0.839-0.005,1.292,0.27,1.285,1.311
		c-0.026,3.802-0.026,7.604-0.003,11.406c0.006,0.991-0.352,1.38-1.238,1.362C154.415,374.942,152.435,374.968,150.455,374.969z" className="logo_exper archi_svg"></path></g></svg>
)

export const LogoInfoIcon = (props = {
	classStyle: '',
	idStyle: '',
	style: '',
}) => (
	<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="506px" height="500px" viewBox="0 0 506 500" className={props.classStyle} id={props.idStyle} style={props.style}><g><path d="
		M254.634,85.776c70.667-0.001,141.334-0.002,212.001,0.009c1.559,0,3.234-0.179,4.646,0.313c1.722,0.598,3.293,1.72,4.803,2.79
		c2.915,2.065,2.95,5.265,2.969,8.341c0.031,5.175-0.15,10.352-0.162,15.528c-0.01,4.568,0.135,9.138,0.132,13.706
		c-0.004,7.483-0.096,14.967-0.104,22.451c-0.005,4.57,0.12,9.14,0.114,13.71c-0.009,7.32-0.101,14.641-0.112,21.962
		c-0.006,4.459,0.109,8.92,0.105,13.379c-0.005,7.429-0.092,14.858-0.09,22.288c0.002,8.147,0.088,16.293,0.093,24.44
		c0.003,5.118-0.109,10.235-0.111,15.354c-0.002,4.459,0.12,8.92,0.111,13.379c-0.015,7.375-0.12,14.75-0.128,22.125
		c-0.006,4.625,0.143,9.248,0.139,13.873c-0.005,7.318-0.104,14.639-0.117,21.957c-0.008,4.461,0.104,8.92,0.098,13.381
		c-0.011,7.486-0.069,14.973-0.115,22.459c-0.016,2.418-0.14,4.838-0.057,7.25c0.095,2.736-1.706,4.313-3.494,5.867
		c-2.301,2.002-5.101,2.33-8.086,2.326c-42.165-0.049-84.33-0.041-126.495-0.043c-13.943-0.002-27.887,0.006-41.83,0.012
		c-0.446,0-0.895-0.004-1.338,0.033c-2.625,0.227-3.29,0.914-3.296,3.537c-0.018,8.156-0.013,16.313-0.01,24.471
		c0,2.48,0.809,3.365,3.39,3.387c6.187,0.051,12.374,0.016,18.561,0.014c2.229-0.002,4.46-0.053,6.688-0.004
		c1.773,0.037,2.642,0.908,2.637,2.508c-0.012,4.242-0.076,8.486-0.027,12.729c0.017,1.488-0.636,2.135-2.047,2.203
		c-0.557,0.027-1.114,0.049-1.672,0.049c-40.326,0-80.652,0-120.978,0c-4.288,0-8.575,0.002-12.862-0.016
		c-2.082-0.008-2.38-0.273-2.392-2.242c-0.024-4.244-0.031-8.488,0.002-12.73c0.016-2.123,0.457-2.533,2.555-2.529
		c7.858,0.008,15.717,0.029,23.575,0.045c0.39,0.002,0.782,0.002,1.17-0.027c3.395-0.254,3.925-0.807,3.926-4.115
		c0.002-7.607,0.004-15.213-0.005-22.818c-0.005-4.133-0.323-4.473-4.446-4.475c-30.174-0.01-60.348-0.006-90.522-0.01
		c-26.771-0.002-53.543-0.006-80.314-0.01c-0.725,0-1.452-0.063-2.174-0.023c-5.138,0.285-8.114-2.721-10.177-6.799
		c-0.604-1.193-0.509-2.795-0.513-4.211c-0.032-14.66-0.018-29.32-0.019-43.98c-0.001-76.166-0.002-152.333-0.002-228.5
		c0-1.267,0.017-2.535,0.031-3.802c0.043-3.835,2.386-6.106,5.387-8.07c2.16-1.414,4.482-1.471,6.891-1.471
		C112.185,85.779,183.409,85.778,254.634,85.776z M253.909,108.47c-28.608,0-57.217-0.003-85.826,0.001
		c-37.921,0.005-75.843,0.019-113.765,0.024c-4.797,0-4.833,0.021-4.833,4.767c0,39.513,0,79.026,0,118.539
		c0,30.915,0,61.831,0.002,92.747c0,4.225,0.371,4.584,4.659,4.584c133.067,0,266.133,0,399.199,0c0.781,0,1.652,0.207,2.32-0.066
		c0.947-0.387,1.993-0.969,2.535-1.77c0.501-0.744,0.533-1.887,0.535-2.854c0.023-21.877,0.013-43.756,0.013-65.633
		c-0.001-37.529,0.002-75.059,0.002-112.588c0-11.297,0.005-22.594-0.006-33.891c-0.003-2.965-0.883-3.805-3.907-3.833
		c-0.39-0.003-0.78-0.002-1.171-0.002c-25.652-0.008-51.306-0.021-76.958-0.023C335.775,108.468,294.843,108.471,253.909,108.47z
		 M253.883,363.17c5.68-0.369,9.487-3.531,9.723-9.008c0.203-4.703-4.518-9.344-9.504-9.037c-4.528,0.279-9.891,3.211-9.906,9.133
		C244.184,359.111,248.982,363.092,253.883,363.17z M227.22,354.094c-0.045-3.156-2.66-5.611-5.915-5.557
		c-3.277,0.057-6.526,2.875-6.522,5.66c0.004,2.682,3.452,5.637,6.54,5.604C224.961,359.764,227.27,357.529,227.22,354.094z" className="logo_exper dev_svg"></path> <path d="
		M393.806,130.61c4.4-0.695,9.638,0.719,14.773,2.032c4.953,1.267,9.399,3.811,12.921,7.702c3.393,3.748,5.156,8.014,5.94,13.048
		c0.874,5.613,0.169,11.064-0.173,16.585c-0.245,3.958-0.389,7.923-0.736,11.872c-0.303,3.438-0.861,6.854-1.248,10.286
		c-0.373,3.312,0.494,6.496,1.998,9.318c0.926,1.739,2.845,3.012,4.453,4.333c2.663,2.187,6.191,1.851,9.277,2.835
		c1.81,0.578,2.582,1.063,2.59,2.96c0.015,3.555,0.016,7.11-0.066,10.663c-0.027,1.172-0.584,2.136-1.938,2.325
		c-0.827,0.115-1.657,0.217-2.489,0.296c-3.393,0.323-6.572,1.378-9.059,3.648c-1.4,1.279-2.338,3.13-3.248,4.845
		c-2.754,5.188-1.229,10.655-0.828,16.023c0.196,2.612,0.882,5.19,1.051,7.803c0.38,5.873,0.725,11.756,0.785,17.639
		c0.03,2.941-0.128,6.037-1.031,8.799c-1.349,4.127-3.178,8.244-6.926,10.908c-2.708,1.924-5.661,3.521-8.569,5.152
		c-0.736,0.414-1.76,0.291-2.586,0.59c-7.256,2.627-14.821,1.76-22.289,1.963c-1.975,0.053-2.27-0.277-2.276-2.16
		c-0.017-4.131-0.026-8.264,0.001-12.396c0.016-2.322,0.256-2.422,2.571-2.543c2.556-0.133,5.142-0.271,7.642-0.768
		c2.415-0.48,4.255-2.145,5.712-4.045c3.119-4.072,3.777-8.828,3.347-13.736c-0.653-7.445-1.728-14.861-2.142-22.316
		c-0.238-4.285,0.1-8.689,0.887-12.912c0.525-2.816,2.191-5.498,4.406-7.569c3.239-3.029,7.048-4.994,11.513-5.674
		c0.686-0.105,1.297-0.687,1.942-1.047c-0.482-0.572-0.872-1.282-1.472-1.682c-0.722-0.48-1.597-0.799-2.451-1.012
		c-4.833-1.207-8.66-3.973-11.59-7.8c-1.257-1.641-2.087-3.793-2.502-5.833c-1.234-6.076-1.198-12.223-0.377-18.371
		c0.211-1.579,0.152-3.193,0.328-4.778c0.423-3.809,0.91-7.61,1.374-11.414c0.323-2.646,0.479-5.264-0.8-7.762
		c-0.294-0.575-0.38-1.264-0.506-1.91c-0.646-3.32-2.931-5.254-5.766-6.752c-3.045-1.609-6.335-1.898-9.703-1.874
		c-2.293,0.017-2.401-0.103-2.408-2.293c-0.015-4.353,0.032-8.706-0.028-13.058c-0.021-1.433,0.515-1.983,1.956-1.939
		C388.294,130.661,390.525,130.61,393.806,130.61z" className="logo_exper dev_svg"></path> <path d="
		M88.618,217.198c1.212,0.387,1.86,0.699,2.54,0.793c3.386,0.467,6.411,1.819,9.159,3.74c2.132,1.49,3.962,3.292,5.214,5.644
		c2.708,5.092,3.175,10.642,2.992,16.167c-0.208,6.312-1.059,12.603-1.642,18.902c-0.277,3.002-1.131,6.074-0.737,8.984
		c0.531,3.924,1.227,8.117,4.962,10.689c1.752,1.207,3.462,2.529,5.822,2.555c2.168,0.023,4.331,0.377,6.5,0.445
		c1.153,0.037,1.663,0.516,1.669,1.566c0.027,4.684,0.034,9.369,0.039,14.053c0.001,1.01-0.533,1.539-1.563,1.506
		c-6.182-0.193-12.45,0.164-18.521-0.75c-5.938-0.895-11.758-3.068-16.159-7.451c-1.947-1.938-3.444-4.434-4.72-6.893
		c-3.373-6.5-2.762-13.518-2.271-20.48c0.314-4.449,0.67-8.895,1.035-13.341c0.396-4.817,1.218-9.638,1.134-14.444
		c-0.101-5.726-2.722-10.112-8.379-12.668c-2.53-1.144-5.018-1.277-7.586-1.492c-1.306-0.109-1.99-0.608-2.032-1.865
		c-0.044-1.315-0.004-2.634-0.002-3.951c0.003-2.696,0.019-5.392,0.002-8.088c-0.009-1.403,0.713-2.127,2.062-2.354
		c1.869-0.316,3.758-0.551,5.597-0.991c3.629-0.868,6.638-2.973,8.275-6.139c1.729-3.343,2.596-7.061,2.137-11.015
		c-0.85-7.313-1.545-14.647-2.113-21.987c-0.45-5.812-1.053-11.687,0.308-17.436c0.58-2.45,1.972-4.715,3.023-7.051
		c1.384-3.072,3.932-5.128,6.592-7c1.553-1.092,3.289-2.039,5.079-2.672c3.255-1.151,6.601-2.059,9.933-2.981
		c1.062-0.294,2.199-0.419,3.306-0.44c4.293-0.081,8.588-0.053,12.881-0.143c1.559-0.033,2.031,0.641,2.016,2.088
		c-0.045,4.574-0.031,9.149,0.02,13.723c0.012,1.091-0.431,1.504-1.474,1.485c-1.06-0.019-2.12-0.005-3.179,0.01
		c-3.988,0.057-7.585,1.293-10.428,4.04c-1.216,1.175-2.173,2.82-2.719,4.427c-1.677,4.934-1.645,9.936-0.672,15.092
		c0.844,4.468,0.886,9.083,1.315,13.63c0.14,1.478,0.646,2.966,0.531,4.415c-0.338,4.268-0.289,8.705-1.543,12.725
		c-1.387,4.448-4.348,8.252-9.046,10.164c-2.037,0.829-4.151,1.488-6.141,2.412C90.791,215.305,89.942,216.197,88.618,217.198z" className="logo_exper dev_svg"></path> <path d="
		M254.729,135.634c34.023,0,68.047,0,102.07,0.001c2.606,0,2.757,0.141,2.754,2.701c-0.004,3.251,0.022,6.503-0.061,9.753
		c-0.05,1.93-0.489,2.277-2.433,2.352c-0.669,0.026-1.339,0.013-2.008,0.013c-67.043,0.001-134.085,0.001-201.128,0.001
		c-4.038,0-4.067-0.03-4.063-4.066c0.003-2.701-0.003-5.401,0.005-8.102c0.008-2.526,0.112-2.638,2.625-2.64
		c15.227-0.008,30.454-0.011,45.68-0.013C217.025,135.632,235.877,135.633,254.729,135.634z" className="logo_exper dev_svg"></path> <path d="
		M248.368,234.454c31.568,0,63.136-0.003,94.704,0.003c5.157,0.001,4.188,0.056,4.252,4.187c0.047,2.976-0.041,5.953,0.02,8.928
		c0.031,1.498-0.654,2.107-2.085,2.105c-1.004-0.001-2.008,0.03-3.012,0.03c-63.192,0.002-126.384,0.002-189.576,0.001
		c-2.773,0-2.787-0.003-2.801-2.742c-0.017-3.417-0.013-6.834-0.008-10.251c0.003-2.083,0.14-2.248,2.295-2.25
		c12.772-0.016,25.545-0.012,38.317-0.012C209.772,234.453,229.07,234.454,248.368,234.454z" className="logo_exper dev_svg"></path> <path d="
		M225.138,209.453c24.197,0.002,48.394,0.002,72.59,0.003c2.49,0,2.658,0.168,2.658,2.625c0,3.416,0.009,6.831,0.002,10.247
		c-0.005,2.285-0.254,2.518-2.625,2.537c-0.726,0.006-1.45-0.002-2.175-0.002c-47.447,0.001-94.894,0.002-142.341,0.003
		c-3.382,0-3.382,0-3.383-3.349c0-3.306,0.078-6.614-0.032-9.916c-0.056-1.683,0.547-2.175,2.215-2.17
		c16.949,0.045,33.897,0.023,50.846,0.021C210.308,209.45,217.723,209.451,225.138,209.453z" className="logo_exper dev_svg"></path> <path d="
		M212.042,174.953c-16.555,0-33.109-0.004-49.664,0.003c-3.4,0.001-6.801,0.085-10.201,0.055c-2.032-0.018-2.303-0.348-2.312-2.344
		c-0.015-3.361,0.024-6.722-0.035-10.082c-0.026-1.512,0.504-2.23,2.127-2.206c3.591,0.053,7.185,0.03,10.776-0.013
		c2.876-0.034,5.75-0.196,8.626-0.205c2.932-0.009,5.864,0.111,8.796,0.154c1.879,0.028,3.758,0.038,5.637,0.022
		c2.934-0.024,5.868-0.081,8.803-0.115c2.824-0.033,5.648-0.066,8.473-0.078c3.426-0.015,6.852-0.034,10.278-0.001
		c2.82,0.026,5.639,0.124,8.458,0.194c0.222,0.005,0.444,0.07,0.665,0.064c2.977-0.088,5.953-0.24,8.929-0.26
		c2.597-0.017,5.194,0.138,7.792,0.172c2.771,0.037,5.542,0.022,8.314,0.029c3.427,0.008,6.855,0.039,10.282,0.013
		c2.877-0.021,5.754-0.122,8.631-0.162c2.157-0.03,4.318-0.083,6.47,0.029c0.39,0.02,1.046,0.779,1.059,1.212
		c0.119,4.128,0.171,8.259,0.138,12.389c-0.009,1.212-1.082,1.195-2.004,1.196c-4.905,0.002-9.812,0.012-14.717,0.013
		c-13.323,0.003-26.646,0.003-39.97,0.004c-1.784,0-3.568,0-5.353,0C212.042,175.008,212.042,174.98,212.042,174.953z" className="logo_exper dev_svg"></path> <path d="
		M196.995,199.873c-14.719-0.001-29.438,0.003-44.157-0.004c-2.984-0.001-2.983-0.02-2.977-2.908
		c0.006-3.306-0.024-6.612,0.025-9.917c0.032-2.095,0.352-2.364,2.461-2.364c29.829-0.001,59.657,0,89.486,0.004
		c2.188,0,2.464,0.157,2.523,2.293c0.102,3.744,0.033,7.491,0.061,11.237c0.012,1.614-1.051,1.665-2.261,1.664
		c-10.816-0.013-21.632-0.006-32.449-0.006C205.469,199.874,201.232,199.874,196.995,199.873z" className="logo_exper dev_svg"></path></g></svg>
)

export const LogoDevIcon = (props = {
	classStyle: '',
	idStyle: '',
	style: '',
}) => (
	<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="509px" height="413px" viewBox="0 0 509 413" className={props.classStyle} id={props.idStyle} style={props.style}><g><path d="
		M28.795,287.384c0.038-57.906-0.019-117.596-0.018-175.503c0-26.13-0.102-52.262,0.09-78.391c0.027-3.619,1.084-7.484,5.867-9.189
		c1.686-0.601,3.672-0.743,5.538-0.835c2.822-0.139,5.661-0.058,8.491-0.058c58.918-0.001,117.836-0.001,176.753-0.001
		c0,0.002,0,0.005,0,0.007c61.149,0,122.3-0.004,183.45,0.016c1.84,0.001,3.795-0.031,5.482,0.465
		c1.42,0.417,2.729,1.441,3.73,2.444c1.803,1.801,1.9,4.107,1.965,6.338c0.09,3.185,0.1,6.372,0.1,9.558
		c0.016,42.792,0.023,85.585,0.031,128.377c0.002,2.047-0.029,4.095,0.01,6.142" className="logo_exper devweb_svg"></path> <path d="
		M409.125,176.625c0.018-9.696-0.018-19.393-0.018-29.089c-0.002-24.264,0.004-48.527,0.012-72.791
		c0.004-12.428,0.016-24.855,0.023-37.283c0.002-2.017-0.064-2.063-2.473-2.048c-1.416,0.009-2.832-0.007-4.248-0.007
		c-118.324,0.002-236.648,0.004-354.975,0.011c-4.533,0-4.109-0.338-4.109,3.446c0.006,52.125,0.001,104.25-0.002,156.374
		c0,25.266,0.007,50.531-0.025,75.797c-0.002,1.348,0.672,1.734,2.158,1.727c6.806-0.031,18.102,0.049,24.907,0" className="logo_exper devweb_svg"></path> <path d="
		M480.229,273.431c0,29.269,0.035,58.536-0.057,87.803c-0.01,2.899-0.695,5.935-3.846,7.75c-1.473,0.848-3.49,1.452-5.262,1.456
		c-41.051,0.076-82.104,0.06-123.154,0.028c-1.16-0.001-2.367-0.242-3.467-0.57c-2.426-0.723-3.975-2.282-4.609-4.293
		c-0.574-1.825-1.029-3.742-1.033-5.62c-0.061-30.223-0.041-60.447-0.043-90.67c0-26.855-0.025-53.711,0.027-80.566
		c0.006-2.301,0.322-4.63,0.824-6.895c0.602-2.713,3.23-5.276,7.221-5.315c1.959-0.019,3.916-0.112,5.875-0.113
		c19.271-0.011,38.543-0.01,57.813-0.01c19.109,0,38.217-0.004,57.324,0.014c1.785,0.001,3.584,0.05,5.35,0.242
		c3.412,0.37,5.758,3.088,6.465,5.873c0.84,3.313,0.545,6.611,0.551,9.912c0.047,26.992,0.025,53.984,0.025,80.976
		C480.23,273.431,480.229,273.431,480.229,273.431z M353.211,273.294c0.002,0,0.004,0,0.004,0c0,26.492,0,52.984,0.002,79.477
		c0,2.479,0.08,2.544,2.984,2.543c19.273-0.004,38.549-0.012,57.822-0.014c16.008-0.002,32.014,0.004,48.021,0.002
		c2.4-0.001,3.721-1.101,3.746-3.1c0.01-0.774-0.006-1.548-0.006-2.322c-0.002-51.618,0.006-103.237-0.025-154.856
		c-0.002-3.556,0.879-3.565-4.199-3.566c-16.279-0.002-32.559-0.005-48.84,0.003c-18.783,0.009-37.566,0.03-56.35,0.046
		c-3.15,0.003-3.15,0.002-3.16,2.583c0,0.182,0,0.364,0,0.546C353.211,220.855,353.211,247.074,353.211,273.294z" className="logo_exper devweb_svg"></path> <path d="
		M137.556,285.631c31.9,0,68.638-0.003,100.536-0.001c51.926,0.003,92.596-0.002,100.66-0.002" className="logo_exper devweb_svg"></path> <path d="
		M338.752,324.584c-12.846,0.007-35.576-0.001-48.424-0.001c-38.584,0-77.168,0-115.753,0c-10.71,0-26.311,0.009-37.02,0.004" className="logo_exper devweb_svg"></path> <path d="
		M137.555,297.111c0,21.073,0.004,42.147-0.006,63.221c-0.002,2.284-0.256,4.509-1.592,6.61c-1.561,2.456-4.064,3.46-7.166,3.519
		c-4.572,0.088-9.146,0.029-13.72,0.03c-11.813,0.001-23.63,0.076-35.442-0.033c-5.479-0.051-8.316-2.291-8.844-6.585
		c-0.586-4.778-0.37-9.632-0.373-14.452c-0.02-30.724-0.004-61.447,0-92.171c0-7.601-0.035-15.201,0.017-22.802
		c0.015-2.175,0.157-4.34,1.722-6.278c1.535-1.903,3.541-3.046,6.264-3.188c1.898-0.098,3.809-0.052,5.715-0.052
		c14.645-0.004,29.291,0.062,43.936-0.037c5.438-0.037,9,3.012,9.261,7.375c0.237,3.996,0.192,8.006,0.196,12.01
		c0.014,14.244-0.004,28.489-0.01,42.733c-0.002,3.366,0,6.732,0,10.1C137.525,297.111,137.541,297.111,137.555,297.111z
		 M132.818,295.372c0.006,0,0.011,0,0.017,0c0-3.687,0.001-7.374,0-11.062c-0.001-12.655-0.015-25.31,0.017-37.965
		c0.004-1.269-0.492-1.852-2.076-1.788c-1.686,0.067-3.375,0.016-5.064,0.016c-16.279-0.001-32.561-0.008-48.842,0.002
		c-2.088,0.002-2.396,0.288-2.396,1.96c-0.006,24.173-0.011,48.345-0.012,72.518c0,8.24,0.008,16.479,0.025,24.72
		c0.004,1.893,0.369,2.259,2.27,2.261c17.914,0.017,35.828,0.027,53.743,0.026c2.192,0,2.319-0.142,2.319-1.933
		C132.818,327.876,132.818,311.624,132.818,295.372z M103.703,231.772c-0.219,0-0.436,0-0.653,0
		c-2.772,0.003-5.547-0.014-8.319,0.017c-1.246,0.014-2.09,0.604-2.289,1.609c-0.178,0.895,0.328,1.629,1.427,1.891
		c0.822,0.196,1.688,0.362,2.536,0.368c4.623,0.031,9.245,0.01,13.867,0.002c0.76-0.001,1.561,0.102,2.271-0.063
		c1.21-0.28,2.456-0.679,2.394-2.036c-0.051-1.145-0.986-1.764-2.75-1.781C109.359,231.753,106.53,231.772,103.703,231.772z" className="logo_exper devweb_svg"></path> <path d="
		M226.371,327.366c18.133,0,36.266-0.001,54.398,0.001c2.617,0.001,2.529,0.008,2.525,2.197c-0.014,8.239,0.037,16.478,0.053,24.717
		c0.002,1.136,0.053,2.287-0.148,3.402c-0.072,0.407-0.76,0.968-1.258,1.045c-1.053,0.161-2.166,0.051-3.256,0.051
		c-35.557-0.001-71.114-0.001-106.672-0.002c-2.633,0-2.604,0-2.601-2.183c0.011-9.06,0.003-18.118,0.001-27.178
		c0-2.052,0-2.057,2.396-2.057c18.188-0.001,36.375,0,54.561,0C226.371,327.362,226.371,327.364,226.371,327.366z" className="logo_exper devweb_svg"></path> <path d="
		M137.75,268.392c0.865,0.06,9.913,0.013,10.784,0.013c61.259,0,128.964-0.014,190.224-0.013" className="logo_exper devweb_svg"></path> <path d="
		M70.376,324.604c-8.379-0.049-21.353,0.002-29.731-0.044c-2.726-0.015-5.561-0.151-7.496-2.029
		c-1.313-1.275-2.574-2.729-3.243-4.287c-0.736-1.717-1.04-3.642-1.062-5.479c-0.098-8.46-0.006-16.921-0.049-25.381
		c-0.008-1.321,0.567-1.754,2.101-1.75c11.589,0.024,27.892,0.001,39.48,0" className="logo_exper devweb_svg"></path> <path d="
		M225.841,370.174c-19.759-0.001-39.519,0.002-59.276-0.005c-2.822-0.001-5.646-0.108-8.467-0.058
		c-1.422,0.025-1.441-0.597-1.135-1.379c0.869-2.226,2.713-3.791,5.096-4.92c3.193-1.513,6.617-2.028,10.311-2.022
		c36.633,0.054,73.268-0.021,109.9,0.077c5.289,0.014,9.984,1.667,12.867,5.771c1.107,1.576,0.615,2.414-1.535,2.471
		c-2.936,0.076-5.873,0.063-8.811,0.064C265.141,370.178,245.492,370.175,225.841,370.174z" className="logo_exper devweb_svg"></path></g></svg>
)
export const LogoRoueIcon = (props = {
	classStyle: '',
	idStyle: '',
	style: '',
}) => (
	<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="300px" height="296.924px" viewBox="88.833 87.743 300 296.924" className={props.classStyle} id={props.idStyle} style={props.style} >
		<g>
			<path d="M139.691,296.836c-4.303-10.283-8.412-20.102-12.736-30.436c-4.839,0-10.363,0.021-15.887-0.004
		c-10.039-0.047-15.479-5.51-15.488-15.541c-0.008-8.936-0.018-17.87,0.014-26.807c0.029-8.368,5.703-14.208,14.098-14.407
		c5.181-0.123,10.37,0.054,15.549-0.116c1.014-0.034,2.569-0.808,2.904-1.634c3.827-9.417,7.466-18.912,11.397-29.007
		c-2.926-2.87-6.458-6.458-10.126-9.904c-8.119-7.628-8.221-16.052-0.296-23.796c5.888-5.754,11.648-11.64,17.525-17.405
		c7.327-7.189,15.361-7.081,22.522,0.183c3.219,3.267,6.543,6.432,9.72,9.738c1.283,1.333,2.264,1.271,3.957,0.531
		c8.271-3.615,16.607-7.091,25.022-10.358c1.988-0.772,2.755-1.577,2.74-3.691c-0.039-5.4,0.379-10.807,0.203-16.198
		c-0.203-6.185,6.729-13.307,12.812-12.922c2.105,0.136,4.236-0.157,6.354-0.163c7.658-0.02,15.317-0.03,22.976,0.011
		c8.553,0.045,14.604,6.033,14.76,14.653c0.088,4.937,0.125,9.879-0.023,14.813c-0.063,1.996,0.657,2.796,2.473,3.512
		c8.397,3.312,16.754,6.73,25.039,10.315c1.684,0.728,2.664,0.771,3.961-0.553c3.785-3.862,7.631-7.671,11.573-11.375
		c5.455-5.121,13.796-5.107,19.157,0.089c7.005,6.786,13.936,13.65,20.799,20.581c5.518,5.571,5.455,14.301-0.076,20.021
		c-3.599,3.721-7.269,7.374-11.022,10.936c-1.347,1.278-1.372,2.244-0.642,3.932c3.68,8.486,7.313,17.001,10.605,25.643
		c1.127,2.959,3.271,2.219,5.138,2.269c4.585,0.124,9.181-0.193,13.767-0.095c8.256,0.177,13.849,5.888,13.91,14.168
		c0.072,9.521,0.08,19.046,0.002,28.571c-0.065,8-5.707,13.732-13.774,13.977c-5.282,0.158-10.572,0.124-15.856,0.025
		c-1.828-0.035-2.533,0.56-3.225,2.32c-3.334,8.504-6.82,16.952-10.511,25.309c-0.837,1.894-0.651,2.896,0.737,4.217
		c3.582,3.403,7.068,6.904,10.56,10.403c5.741,5.759,6.063,14.64,0.5,20.325c-6.821,6.976-13.78,13.823-20.813,20.585
		c-5.586,5.374-13.767,5.316-19.404-0.003c-3.76-3.551-7.444-7.187-11.045-10.897c-1.457-1.502-2.57-1.584-4.563-0.712
		c-8.16,3.577-16.438,6.901-24.734,10.157c-1.787,0.701-2.625,1.443-2.563,3.479c0.152,4.814,0.088,9.64,0.042,14.458
		c-0.09,9.091-6.024,15.021-15.06,15.054c-8.365,0.029-16.734-0.145-25.095,0.049c-9.866,0.229-18.425-5.749-17.076-17.397
		c0.471-4.062-0.009-8.224,0.105-12.334c0.053-1.877-0.764-2.519-2.439-3.177c-8.51-3.354-16.982-6.813-25.362-10.474
		c-1.725-0.753-2.686-0.759-3.955,0.549c-3.522,3.628-7.121,7.186-10.733,10.728c-5.922,5.806-13.905,6.189-19.906,0.528
		c-7.271-6.858-14.394-13.896-21.257-21.157c-5.897-6.242-4.718-14.848,2.222-21.203
		C132.96,303.722,136.512,299.982,139.691,296.836z M239.026,151.848c-47.265,0.209-85.75,37.543-86.257,85.06
		c-0.516,48.229,38.22,87.236,85.817,87.228c47.833-0.011,87.115-37.544,86.805-86.627
		C325.086,188.732,286.063,152.116,239.026,151.848z" className="roue1 roue_svg"></path>

			<path d="M576.945,156.597c-4.868,4.928-9.817,9.943-14.771,14.952c-5.662,5.726-8.539,5.783-14.214,0.165
		c-5.765-5.708-11.489-11.452-17.201-17.215c-4.187-4.223-4.329-7.513-0.114-11.837c5.33-5.471,10.897-10.707,16.189-15.873
		c-2.245-5.749-4.225-11.102-6.496-16.328c-0.332-0.766-2.056-1.286-3.146-1.306c-6.713-0.12-13.43-0.038-20.143-0.07
		c-6.255-0.032-8.751-2.514-8.783-8.801c-0.043-8.583-0.052-17.162-0.002-25.744c0.031-5.548,2.701-8.121,8.329-8.139
		c6.95-0.025,13.902,0.073,20.849-0.097c1.056-0.025,2.594-0.976,3.029-1.91c2.043-4.345,3.885-8.797,5.534-13.307
		c0.354-0.965,0.017-2.712-0.688-3.451c-4.535-4.771-9.295-9.329-13.92-14.015c-5.158-5.228-5.164-8.108,0.035-13.366
		c6.037-6.103,12.127-12.153,18.242-18.179c3.359-3.315,6.821-3.508,10.25-0.279c5.217,4.914,10.301,9.978,15.282,15.127
		c1.621,1.674,2.918,1.67,4.903,0.748c3.932-1.828,7.968-3.462,12.052-4.922c1.75-0.626,2.364-1.452,2.339-3.274
		c-0.09-6.698-0.043-13.4-0.029-20.1c0.012-6.524,2.459-9.014,8.872-9.024c8.601-0.014,17.199-0.016,25.8-0.011
		c5.381,0.005,8.063,2.598,8.1,7.948c0.049,7.054,0.066,14.106-0.021,21.158c-0.023,1.808,0.557,2.63,2.307,3.261
		c4.304,1.549,8.453,3.53,12.768,5.046c1.102,0.386,3.027,0.165,3.822-0.579c5.234-4.896,10.211-10.067,15.378-15.04
		c3.662-3.524,7.177-3.481,10.763,0.056c6.521,6.433,13.009,12.899,19.439,19.418c3.427,3.472,3.461,6.941,0.063,10.456
		c-4.98,5.15-10.047,10.221-15.168,15.234c-1.418,1.388-1.512,2.526-0.68,4.311c1.883,4.034,3.608,8.155,5.102,12.347
		c0.675,1.897,1.562,2.514,3.527,2.482c6.948-0.111,13.901-0.068,20.85-0.042c5.421,0.023,8.058,2.605,8.077,7.95
		c0.035,8.935,0.026,17.868,0.012,26.801c-0.008,5.042-2.729,7.864-7.827,7.928c-7.066,0.089-14.14,0.132-21.204,0.003
		c-2.032-0.036-2.834,0.662-3.496,2.512c-1.459,4.082-3.104,8.112-4.95,12.032c-0.891,1.892-0.865,3.068,0.713,4.578
		c4.846,4.627,9.563,9.391,14.253,14.176c4.46,4.552,4.402,7.756-0.138,12.316c-6.137,6.166-12.271,12.334-18.487,18.417
		c-3.685,3.601-7.116,3.701-10.838,0.165c-5.112-4.856-10.112-9.838-14.993-14.927c-1.626-1.695-2.855-1.602-4.816-0.658
		c-3.91,1.885-7.924,3.619-12.039,4.993c-2.026,0.674-2.456,1.62-2.434,3.508c0.079,6.934,0.051,13.87,0.016,20.806
		c-0.025,5.314-2.687,8.062-8.002,8.102c-8.953,0.064-17.906,0.07-26.859-0.015c-5.104-0.048-7.834-2.813-7.893-7.923
		c-0.075-6.936-0.063-13.872,0.02-20.806c0.023-2.01-0.447-3.04-2.618-3.771C586.999,160.939,582.29,158.779,576.945,156.597z
		 M565.619,87.457c-0.096,27.981,21.967,50.253,49.908,50.384c27.928,0.13,50.582-22.075,50.883-49.606
		c0.299-27.579-22.046-50.543-50.045-50.869C588.354,37.041,565.714,59.567,565.619,87.457z" className="roue1 roue_svg"></path>

			<path d="M569.971,284.31c5.688-2.291,10.43-4.365,15.297-6.076c1.907-0.67,2.594-1.506,2.567-3.469
		c-0.088-6.818-0.071-13.639-0.021-20.457c0.045-6.045,2.582-8.543,8.651-8.572c8.601-0.038,17.202-0.051,25.804-0.032
		c5.563,0.011,8.281,2.68,8.311,8.153c0.035,6.938,0.072,13.875-0.028,20.81c-0.028,1.946,0.528,2.826,2.488,3.495
		c4.219,1.438,8.356,3.169,12.387,5.08c1.746,0.826,2.716,0.979,4.177-0.568c4.351-4.615,8.938-9.005,13.424-13.496
		c5.527-5.539,8.343-5.672,13.921-0.221c6.139,6.001,12.141,12.143,18.156,18.268c3.313,3.37,3.436,6.703,0.137,10.165
		c-4.951,5.19-10.019,10.275-15.175,15.264c-1.567,1.516-1.592,2.723-0.733,4.59c1.855,4.044,3.564,8.171,5.06,12.359
		c0.669,1.87,1.747,2.149,3.432,2.138c6.717-0.05,13.434-0.023,20.147,0c6.344,0.022,8.822,2.466,8.834,8.689
		c0.021,8.7,0.042,17.399,0.025,26.101c-0.011,5.125-2.67,7.836-7.811,7.882c-7.655,0.067-15.313,0.043-22.972,0.07
		c-0.224,0-0.444,0.136-0.521,0.159c-2.192,5.157-4.451,10.179-6.396,15.317c-0.357,0.949,0.186,2.729,0.944,3.527
		c4.373,4.597,8.938,9.014,13.438,13.488c5.355,5.326,5.455,8.273,0.208,13.597c-6.023,6.111-12.126,12.149-18.249,18.163
		c-3.448,3.389-7.045,3.434-10.509,0.078c-5.064-4.907-10.087-9.867-14.999-14.931c-1.608-1.659-2.971-1.636-4.94-0.728
		c-3.939,1.814-7.955,3.507-12.057,4.916c-1.974,0.677-2.445,1.668-2.421,3.565c0.095,6.938,0.065,13.874,0.036,20.811
		c-0.023,5.38-2.633,8.011-8.004,8.025c-8.955,0.027-17.909,0.023-26.864-0.025c-5.116-0.028-7.809-2.678-7.881-7.862
		c-0.095-6.582-0.157-13.171,0.021-19.749c0.08-2.854-0.588-4.338-3.636-5.188c-3.815-1.066-7.452-2.866-11.038-4.611
		c-1.932-0.941-3.117-0.651-4.578,0.846c-4.928,5.047-9.914,10.047-15.039,14.892c-3.689,3.487-6.867,3.476-10.476-0.016
		c-6.681-6.458-13.271-13.012-19.782-19.64c-3.234-3.292-3.304-6.911,0.012-10.301c5.582-5.706,11.427-11.158,16.904-16.469
		c-2.32-5.919-4.316-11.282-6.599-16.522c-0.335-0.771-2.031-1.314-3.108-1.333c-6.951-0.117-13.902-0.033-20.854-0.067
		c-5.317-0.027-8.028-2.688-8.063-8.057c-0.056-8.699-0.06-17.4,0.036-26.1c0.065-6.071,2.615-8.506,8.707-8.533
		c6.718-0.032,13.435-0.07,20.147,0.029c2.084,0.032,3.06-0.604,3.748-2.695c1.281-3.893,2.875-7.707,4.68-11.39
		c1.064-2.172,1.212-3.528-0.766-5.365c-4.909-4.555-9.58-9.371-14.244-14.182c-4.127-4.257-4.008-7.583,0.232-11.844
		c6.139-6.169,12.281-12.332,18.443-18.478c3.67-3.66,7.414-3.848,11.131-0.184C559.316,273.151,564.71,278.89,569.971,284.31z
		 M558.979,352.958c0.094,28.367,22.328,50.612,50.515,50.538c27.51-0.073,50.354-23.043,50.229-50.497
		c-0.133-27.939-22.554-50.057-50.738-50.04C581.239,302.972,558.889,325.322,558.979,352.958z" className="roue1 roue_svg">
			</path>

			<path d="M282.015,237.993c-0.013,23.722-19.422,42.963-43.22,42.842c-23.794-0.122-42.857-19.458-42.737-43.35
		c0.116-23.246,19.666-42.466,43.062-42.337C262.822,195.276,282.024,214.458,282.015,237.993z" className="roue1 roue_svg">
			</path>

			<path d="M637.186,88.3c0.194,8.719-8.949,20.927-21.271,20.799c-12.018-0.127-21.398-10.265-21.381-21.042
		c0.021-13.258,9.316-21.625,21.427-21.671C627.359,66.339,637.188,76.567,637.186,88.3z" className="roue1 roue_svg">
			</path>

			<path d="M609.745,374.291c-11.637,0.617-21.393-8.538-21.885-20.532c-0.473-11.478,8.871-21.634,20.727-21.889
		c12.422-0.27,20.781,7.807,21.872,19.879C631.659,365.033,621.001,374.676,609.745,374.291z" className="roue1 roue_svg">
			</path>

		</g>
	</svg>
);

export const FaceBookIcon = (props = {
	classStyle: '',
	idStyle: '',
	style: '',
}) => (
	<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="620px" height="630px" viewBox="0 0 620 630" className={props.classStyle} id={props.idStyle} style={props.style}><g><path d="
		M238.085,354.392c-2.393,0-4.342,0.001-6.291,0c-23.163,0-46.327,0.016-69.49-0.006c-11.925-0.012-16.176-4.292-16.184-16.298
		c-0.018-30.829-0.02-61.657,0-92.486c0.007-11.853,4.508-16.371,16.28-16.38c23.163-0.019,46.327-0.006,69.49-0.006
		c1.813,0,3.625,0,6.194,0c0-2.109,0-3.866,0-5.622c0.001-20.497-0.005-40.994,0.003-61.491c0.013-30.299,5.42-59.304,20.816-85.861
		c15.761-27.186,38.695-45.803,68.091-56.572c18.825-6.897,38.369-9.656,58.345-9.665c24.996-0.012,49.993,0.008,74.989,0.052
		c10.741,0.019,15.513,4.769,15.529,15.588c0.045,28.996,0.047,57.992-0.002,86.988c-0.018,10.933-4.585,15.329-15.571,15.446
		c-20.488,0.218-40.993,0.085-61.459,0.904c-11.093,0.443-21.727,3.786-27.107,14.797c-2.492,5.102-4.313,11.073-4.435,16.694
		c-0.493,22.635-0.205,45.286-0.205,68.742c1.938,0,3.854,0,5.77,0c27.496,0,54.992-0.005,82.488,0.003
		c12.49,0.003,16.762,4.294,16.761,16.849c-0.001,30.663-0.011,61.325-0.03,91.987c-0.008,12.372-4.021,16.327-16.588,16.333
		c-27.496,0.014-54.992,0.005-82.488,0.006c-1.804,0-3.608,0-6.164,0c0,2.517,0,4.604,0,6.691
		c-0.001,80.488,0.001,160.978-0.002,241.466c0,13.226-4.165,17.443-17.215,17.444c-31.829,0.003-63.657,0.008-95.486-0.003
		c-11.537-0.004-16.032-4.48-16.033-16.019c-0.01-80.821-0.006-161.643-0.006-242.463
		C238.085,359.368,238.085,357.226,238.085,354.392z" className="faceBook faceBook_svg"></path></g></svg>
);

export const InstagramIcon = (props = {
	classStyle: '',
	idStyle: '',
	style: '',
}) => (
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="601px" height="512px" viewBox="0 0 601 512" className={props.classStyle} id={props.idStyle} style={props.style}><g>
	<path d="M72.1,255.992c0.543-30.813,0.835-61.632,1.707-92.435
		c0.752-26.596,5.515-52.374,19.648-75.475c18.523-30.276,45.796-48.768,80.441-54.73c20.56-3.539,41.689-4.794,62.593-5.109
		c45.475-0.688,90.974-0.429,136.455,0.067c23.784,0.26,47.661,1.07,70.532,8.979c44.344,15.335,71.246,46.487,79.885,92.135
		c3.903,20.625,4.803,42.012,5.123,63.082c0.688,45.143,0.418,90.308-0.07,135.456c-0.258,23.785-1.045,47.66-8.896,70.547
		c-15.232,44.396-46.367,71.338-91.975,80.092c-20.609,3.957-42.008,4.835-63.078,5.156c-45.309,0.69-90.641,0.423-135.956-0.07
		c-23.786-0.259-47.664-1.075-70.535-8.979c-44.355-15.33-71.247-46.497-79.884-92.148c-3.902-20.625-4.342-42.002-5.168-63.085
		C72.093,298.347,72.74,277.161,72.74,256C72.526,255.997,72.313,255.994,72.1,255.992z M488.505,252.412
		c-0.308-0.007-0.616-0.013-0.925-0.02c0-17.998,0.501-36.014-0.135-53.989c-0.688-19.442-1.077-39.056-4.025-58.221
		c-4.876-31.69-22.644-54.154-53.931-63.715c-11.325-3.461-23.481-5.443-35.32-5.758c-41.63-1.106-83.288-1.651-124.932-1.502
		c-26.12,0.093-52.329,0.737-78.327,3.05c-38.328,3.41-64.959,26.874-72.029,64.529c-3.841,20.457-4.398,41.688-4.797,62.601
		c-0.718,37.648-0.495,75.324-0.074,112.982c0.199,17.793,1.064,35.642,2.856,53.341c3.877,38.277,26.744,64.8,64.516,72.099
		c20.104,3.885,41.015,4.454,61.593,4.837c38.482,0.716,76.99,0.489,115.482,0.077c17.293-0.186,34.629-1.174,51.839-2.878
		c16.259-1.61,31.479-6.893,44.56-17.259c21.215-16.813,29.434-40.031,30.691-65.788
		C487.244,322.038,487.584,287.211,488.505,252.412z" classeName="svg0"></path> <path d="M300.772,373.156c-65.301,0.004-117.204-52.085-117.195-117.614
		c0.009-65,52.358-116.724,118.103-116.691c64.494,0.031,116.228,52.612,116.196,118.1
		C417.846,321.082,365.373,373.152,300.772,373.156z M300.949,179.992c-41.717-0.108-76.069,34.042-76.229,75.784
		c-0.161,41.647,34.036,76.049,75.784,76.236c41.615,0.187,76.072-34.072,76.229-75.793
		C376.891,214.563,342.63,180.101,300.949,179.992z" classeName="svg0"></path> <path d="M449.87,134.229c-0.009,15.296-12.452,27.772-27.509,27.58
		c-15.021-0.192-27.287-12.414-27.445-27.35c-0.162-15.148,12.207-27.568,27.488-27.604
		C437.96,106.82,449.879,118.698,449.87,134.229z" classeName="svg0"></path></g></svg>
);

export const LinkedInIcon = (props = {
	classStyle: '',
	idStyle: '',
	style: '',
}) => (
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="515px" height="500px" viewBox="0 0 515 500" className={props.classStyle} id={props.idStyle} style={props.style}><g>
	<path d="M456,452.311c-29.356,0-58.712,0-89.454,0c0-4.668,0.001-8.521,0-12.371
		c-0.027-45.879,0.095-91.761-0.23-137.637c-0.054-7.503-1.479-15.18-3.295-22.45c-6.437-25.778-24.502-34.653-41.643-33.196
		c-22.839,1.943-38.678,22.173-38.72,46.95c-0.081,48.541,0.071,97.081,0.126,145.619c0.005,3.913,0,7.827,0,12.469
		c-29.775,0-58.686,0-88.859,0c0-7.973-0.04-15.526,0.006-23.081c0.473-77.786,0.987-155.572,1.421-233.357
		c0.087-15.624-0.046-15.627,13.94-15.629c21.166-0.002,42.34,0.386,63.492-0.197c7.986-0.22,10.4,2.875,9.756,11.476
		c-0.579,7.727-0.122,15.553-0.122,25.555c26.817-45.458,90.937-49.378,124-28.277c30.433,19.423,43.523,50.51,47.492,88.067
		c0.441,4.177,1.382,8.284,2.09,12.424C456,343.219,456,397.766,456,452.311z" classeName="svg0"></path> <path d="M59,87.124c0.56-0.737,1.456-1.384,1.631-2.224
		c7.293-34.936,41.221-43.029,64.286-33.527c18.184,7.491,29.519,28.259,27.245,49.337c-2.233,20.7-17.718,37.52-37.516,39.833
		c-18.982,2.218-36.689-0.863-48.322-20.536c-3.331-5.634-4.931-12.574-7.324-18.915C59,96.437,59,91.779,59,87.124z" classeName="svg0"></path> <path d="M148.027,451.722c-25.383,0-50.176,0-76.047,0c0-4.254-0.003-7.824,0-11.393
		c0.075-82.812,0.274-165.623,0.059-248.435c-0.024-9.456,2.157-12.802,10.839-12.482c18.8,0.694,37.637,0.429,56.452,0.097
		c6.264-0.109,8.919,1.789,8.913,9.485c-0.07,85.803,0.113,171.608,0.211,257.411C148.456,447.717,148.25,449.03,148.027,451.722z" classeName="svg0"></path></g></svg>
);

export const GitHubIcon = (props = {
	classStyle: '',
	idStyle: '',
	style: '',
}) => (
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="596px" height="500px" viewBox="0 0 596 500" className={props.classStyle} id={props.idStyle} style={props.style}><g>
	<path d="M522,268.681c-0.849,7.047-1.615,14.104-2.561,21.139
		c-2.283,16.982-6.512,33.468-12.529,49.524c-6.924,18.478-15.58,36.035-27.354,51.911c-5.997,8.086-12.038,16.239-18.939,23.53
		c-8.268,8.734-17.502,16.551-26.27,24.817c-10.681,10.07-23.436,16.97-36.354,23.523c-8.679,4.403-17.608,8.346-26.593,12.097
		c-8.775,3.665-18.307,0.342-17.933-12.606c0.573-19.934,0.121-39.896,0.37-59.842c0.148-11.882-1.731-23.285-7.033-33.935
		c-1.809-3.634-4.539-6.809-7.02-10.443c6.361-1.099,12.828-1.861,19.109-3.39c7.998-1.946,16.228-3.659,23.674-6.996
		c13.24-5.935,25.854-13.274,35.104-24.906c11.682-14.688,18.303-31.624,21.01-50.123c2.74-18.716,3.928-37.444-1.283-55.947
		c-3.387-12.028-8.631-23.204-17.082-32.605c-1.707-1.899-2.141-3.559-1.392-6.076c5.514-18.518,4.092-36.823-1.849-55.005
		c-1.011-3.093-2.572-4.432-5.789-4.247c-13.766,0.791-26.322,5.053-37.867,12.591c-5.953,3.889-12.112,7.475-18.303,10.977
		c-1.039,0.588-2.783,0.322-4.084-0.007c-35.371-8.925-70.713-8.89-106.108-0.063c-1.861,0.464-4.438-0.333-6.205-1.36
		c-11.982-6.977-23.149-15.576-36.881-19.06c-5.751-1.458-11.707-2.146-17.595-3.008c-2.766-0.405-4.281,0.975-5.206,3.774
		c-6.047,18.302-7.532,36.744-1.987,55.413c0.75,2.522,0.275,4.135-1.373,6.078c-13.103,15.456-19.566,33.573-20.433,53.63
		c-0.95,21.976,1.396,43.594,10.269,63.956c8.045,18.462,21.199,32.122,39.152,41.81c14.737,7.953,30.454,11.289,46.592,13.839
		c1.537,0.243,3.07,0.507,5.213,0.862c-7.937,8.431-11.437,18.44-13.457,29.147c-0.178,0.946-1.531,1.941-2.566,2.415
		c-11.218,5.124-22.765,7.856-35.794,3.918c1.407,3.067,1.575,5.977-1.885,5.865c-2.098-0.068-4.13-2.153-6.192-3.334
		c1.018-1.621,2.036-3.243,3.13-4.989c-8.848-3.915-16.493-11.063-21.561-20.598c-4.869-9.159-11.841-15.942-20.949-20.534
		c-5.771-2.907-11.942-4.893-18.524-3.287c-1.904,0.465-4.571,1.614-5.042,3.055c-0.459,1.406,1.189,3.768,2.376,5.366
		c1.073,1.444,2.654,2.614,4.214,3.575c13.542,8.336,20.947,21.178,26.408,35.522c2.646,6.95,7.328,12.408,12.898,17.27
		c12.389,10.81,27.119,13.655,42.898,12.775c6.361-0.355,12.686-1.36,19.436-2.115c0.123,1.266,0.333,2.469,0.341,3.674
		c0.087,12.681,0.187,25.362,0.19,38.044c0.002,9.352-6.786,14.478-15.773,11.584c-22.554-7.261-42.549-19.427-61.689-33.064
		c-4.822-3.436-9.017-7.745-13.544-11.603c-12.198-10.394-22.714-22.414-32.023-35.318c-23.019-31.91-37.634-67.365-42.857-106.511
		c-0.919-6.893-1.675-13.807-2.506-20.71c0-8.835,0-17.67,0-26.504c0.262-1.509,0.658-3.009,0.766-4.528
		c2.792-39.349,14.958-75.684,35.696-109.097c10.982-17.694,24.371-33.633,39.859-47.517c9.688-8.682,20.305-16.428,31.039-23.818
		c15.055-10.367,32.139-16.958,49.158-23.124c11.263-4.081,23.406-5.845,35.25-8.167c6.944-1.36,14.072-1.777,21.117-2.619
		c7.41,0,14.82,0,22.229,0c1.101,0.238,2.19,0.608,3.304,0.695c20.584,1.609,40.738,4.831,60.023,12.883
		c16.384,6.841,33.029,13.019,47.144,24.176c10.22,8.081,20.73,15.869,30.313,24.658c7.804,7.159,14.675,15.396,21.515,23.53
		c12.988,15.444,22.791,32.858,30.859,51.313c8.541,19.534,14.189,39.86,17.203,60.933c0.982,6.874,1.691,13.787,2.525,20.682
		C522,251.011,522,259.846,522,268.681z" classeName="svg0"></path> <path d="M219.972,405.014c-2.117-2.053-3.485-3.379-4.854-4.706
		c1.433-1.108,2.872-3.103,4.296-3.092c1.677,0.013,3.34,1.763,5.01,2.76C223.168,401.396,221.912,402.818,219.972,405.014z" classeName="svg0"></path> <path d="M237.615,405.068c-1.762-2.059-2.817-3.292-3.873-4.524
		c1.705-1.189,3.289-2.967,5.158-3.347c1.133-0.23,2.706,1.703,4.082,2.664C241.412,401.385,239.842,402.907,237.615,405.068z" classeName="svg0"></path> <path d="M181.234,387.185c1.101-0.722,2.832-2.295,3.198-2.024
		c1.985,1.471,3.679,3.357,5.272,5.269c0.085,0.103-2.104,2.656-2.459,2.482c-2.191-1.072-4.159-2.601-6.202-3.976
		C181.106,388.352,181.17,387.768,181.234,387.185z" classeName="svg0"></path> <path d="M178.828,375.136c-2.003,1.451-3.687,2.915-3.857,2.757
		c-1.606-1.497-3.063-3.201-4.239-5.052c-0.151-0.237,2.107-2.512,2.304-2.384C174.842,371.628,176.441,373.122,178.828,375.136z" classeName="svg0"></path> <path d="M170.98,362.328c-2.083,1.193-3.465,2.41-3.875,2.142
		c-1.505-0.984-2.755-2.41-3.879-3.843c-0.102-0.13,1.778-2.304,2.055-2.165C166.98,359.313,168.482,360.556,170.98,362.328z" classeName="svg0"></path> <path d="M154.507,349.731c1.404,1.737,2.348,2.905,3.292,4.073
		c-0.687,0.556-1.688,1.722-2.006,1.559c-1.367-0.701-2.533-1.793-3.772-2.742C152.651,351.887,153.282,351.154,154.507,349.731z" classeName="svg0"></path></g></svg>
);

export const TwitterIcon = (props = {
	classStyle: '',
	idStyle: '',
	style: '',
}) => (
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="486px" height="428px" viewBox="0 0 486 428" className={props.classStyle} id={props.idStyle} style={props.style}>
	<g>
	<path d="M444.563,81.25c0,1.042,0,2.083,0,3.125
		c-8.78,10.411-16.974,21.402-26.519,31.058c-8.432,8.531-12.047,17.765-12.614,29.739c-1.686,35.568-8.924,70.221-24.076,102.521
		c-8.199,17.477-18.733,33.999-29.458,50.125c-22.884,34.402-53.376,61.068-91.447,76.559
		c-57.303,23.319-116.04,24.685-174.302,2.25c-14.349-5.525-27.748-13.519-41.584-20.377c0-0.521,0-1.042,0-1.563
		c4.367-0.58,8.717-1.413,13.104-1.693c16.76-1.073,33.406-1.674,49.626-7.546c18.729-6.779,37.521-12.931,53.066-27.46
		c-16.988-5.865-33.083-11.011-46.269-22.966c-9.83-8.911-18.352-18.303-23.478-30.537c-3.375-8.059-2.359-9.56,6.422-9.728
		c7.336-0.142,14.676-0.029,22.015-0.029c0.446-0.874,0.893-1.747,1.339-2.62c-40.716-15.222-59.617-45.561-63.762-87.909
		c11.249,3.144,21.034,5.879,30.82,8.614c0.75-0.617,1.502-1.234,2.254-1.853c-33.103-34.637-38.799-73.766-20.738-117.59
		c22.225,25.54,45.972,48.941,75.429,65.329c29.382,16.347,60.443,27.734,95.317,28.25c-0.322-4.146-0.598-7.475-0.838-10.809
		c-2.409-33.527,8.759-61.797,35.606-81.961c31.644-23.765,76.263-19.525,104.514,7.652c2.207,2.123,7.353,3.29,10.206,2.281
		c12.382-4.379,24.421-9.73,36.584-14.729c2.508-1.03,5.016-2.062,8.148-3.351c-2.823,18.878-18.927,29.163-24.895,45.566
		c7.177-0.908,13.558-2.715,19.859-4.764C434.164,85.12,439.343,83.122,444.563,81.25z" classeName="svg0"></path></g></svg>
);