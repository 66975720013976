import { cloneJson } from "./Tool/CloneCommon";
import moment from "moment";
import axios from 'axios';
import { dispatch } from "./App";
import { AuthenticationLogout } from "./Action/AuthenticationAction";
import { SnackbarOpen } from "./Action/SnackbarAction";

const host = `${process.env.NODE_ENV == 'production' ? '/8000' : ''}/api`;

const cacheConfig = {};

const routeApi = {
    default: '/',

    // Authentication
    authentication_login: '/authentication/login',
    authentication_logout: '/authentication/logout',
}

function Api() {
    let cache = {};
    let currentCall = {};

    this.host = function () {
        return window.location.protocol + '//' + window.location.host;
    };
    this.route = function (options) {
        let route = routeApi[options.route];

        if (!route) {
            console.log(options.route);
            console.log(route);
            dispatch(
                SnackbarOpen({
                    text: '404',
                    variant: 'error',
                })
            );
            return;
        }

        if (options.data) {
            route += '?';
            for (let index in options.data) {
                let dataGet = options.data[index] === null ? '' : options.data[index];
                dataGet = dataGet === undefined ? '' : dataGet;

                if (Array.isArray(dataGet)) {
                    for (let index1 in dataGet) {
                        route += index + "[]=" + dataGet[index1] + "&";
                    }
                }
                else {
                    if (dataGet || dataGet === false) route += index + "=" + dataGet + "&";
                }
            }
            route = route.slice(0, -1);
        }

        for (let key in options.params) {
            let regEx = new RegExp('{' + key + '}', 'gi');
            route = route.replace(regEx, options.params[key]);
        }

        return host + route;
    };
    this.get = function (options, callback) {
        let route = routeApi[options.route];

        if (!route) {
            callback({ status: 404 });
            console.log(options.route);
            console.log(route);
            dispatch(
                SnackbarOpen({
                    text: '404',
                    variant: 'error',
                })
            );
            return;
        }

        if (options.data) {
            route += '?';
            for (let index in options.data) {
                let dataGet = options.data[index] === null ? '' : options.data[index];
                dataGet = dataGet === undefined ? '' : dataGet;

                if (Array.isArray(dataGet)) {
                    for (let index1 in dataGet) {
                        route += index + "[]=" + dataGet[index1] + "&";
                    }
                }
                else {
                    if (dataGet || dataGet === false) route += index + "=" + dataGet + "&";
                }
            }
            route = route.slice(0, -1);
        }

        for (let key in options.params) {
            let regEx = new RegExp('{' + key + '}', 'gi');
            route = route.replace(regEx, options.params[key]);
        }

        if (cache[route] && cache[route].age.isAfter(moment())) {
            let data = cloneJson(cache[route].data);
            callback(data);
        }
        else {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            currentCall[host + route] = source;

            axios.get(host + route, {
                withCredentials: true,
                cancelToken: source.token
            })
                .then(function (response) {
                    delete currentCall[host + route];

                    if (cacheConfig[options.route]) {
                        if (options.route in cacheConfig) {
                            if (!cache[route]) {
                                cache[route] = {
                                    data: cloneJson(response.data),
                                    age: moment().add(cacheConfig[options.route], 's'),
                                };
                                callback(cloneJson(response.data));
                            }
                        }
                    }
                    else {
                        callback(response.data);
                    }
                })
                .catch(err => {
                    console.log(options.route);
                    console.log(route);
                    console.log(err);
                    if (axios.isCancel(err)) {
                        console.log('Requête annulée', err.message);
                    }
                    else if (err.response.status === 401) {
                        dispatch(AuthenticationLogout())
                    }
                    else {
                        if (err.response.data.status === 403 && err.response.data.error.message) {
                            dispatch(
                                SnackbarOpen({
                                    text: err.response.data.error.message,
                                    variant: 'error',
                                })
                            );
                        }
                        callback(err.response.data);
                    }

                    delete currentCall[host + route];
                });
        }
    };
    this.post = function (options, callback) {
        let route = routeApi[options.route];

        for (let key in options.params) {
            let regEx = new RegExp('{' + key + '}', 'gi');
            route = route.replace(regEx, options.params[key]);
        }

        let data;
        if (options.file) {
            data = new FormData();
            for (let property in options.file) {
                data.append(property, options.file[property]);
            }
            if (options.data) {
                data.append('_json', JSON.stringify(options.data));
            }
        }
        else {
            data = JSON.stringify(options.data);
        }
        generateLogs(host, route, data)

        axios.post(host + route, data, { withCredentials: true })
            .then(function (response) {
                callback(response.data);
            })
            .catch(err => {
                console.log(route);
                console.log(err);
                console.log(err.response.data.error.message);
                if (err.response.status === 401) {
                    dispatch(AuthenticationLogout())
                }
                else {
                    if (err.response.data.status === 403 && err.response.data.error.message) {
                        dispatch(
                            SnackbarOpen({
                                text: err.response.data.error.message,
                                variant: 'error',
                            })
                        );
                    }
                    callback(err.response.data);
                }
            });
    };
    this.delete = function (options, callback) {
        let route = routeApi[options.route];

        for (let key in options.params) {
            let regEx = new RegExp('{' + key + '}', 'gi');
            route = route.replace(regEx, options.params[key]);
        }

        generateLogs(host, route)
        axios.delete(host + route, { withCredentials: true })
            .then(function (response) {
                callback(response.data);
            })
            .catch(err => {
                console.log(route);
                console.log(err);
                if (err.response.status === 401) {
                    dispatch(AuthenticationLogout())
                }
                else {
                    if (err.response.data.status === 403 && err.response.data.error.message) {
                        dispatch(
                            SnackbarOpen({
                                text: err.response.data.error.message,
                                variant: 'error',
                            })
                        );
                    }
                    callback(err.response.data);
                }
            });

    };
    this.postDownload = function (options, callback) {
        let route = routeApi[options.route];

        for (let key in options.params) {
            let regEx = new RegExp('{' + key + '}', 'gi');
            route = route.replace(regEx, options.params[key]);
        }

        let data;
        if (options.file) {
            data = new FormData();
            for (let property in options.file) {
                data.append(property, options.file[property]);
            }
            if (options.data) {
                data.append('_json', JSON.stringify(options.data));
            }
        }
        else {
            data = JSON.stringify(options.data);
        }
        generateLogs(host, route, data);
        axios.post(host + route, data, { withCredentials: true, responseType: 'blob' })
            .then(response => new File([response.data], options.filename))
            .then(response => {
                const url = window.URL.createObjectURL(response);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = options.filename;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                callback({ status: 200 });
            })
            .catch(err => {
                console.log(route);
                console.log(err);
                if (err.response.status === 401) {
                    dispatch(AuthenticationLogout())
                }
                else {
                    if (err.response.data.status === 403 && err.response.data.error.message) {
                        dispatch(
                            SnackbarOpen({
                                text: err.response.data.error.message,
                                variant: 'error',
                            })
                        );
                    }
                    callback(err.response.data);
                }
            });
    };
    this.getDownload = function (options, callback) {
        let route = routeApi[options.route];

        if (!route) {
            callback({ status: 404 });
            console.log(options.route);
            console.log(route);
            dispatch(
                SnackbarOpen({
                    text: '404',
                    variant: 'error',
                })
            );
            return;
        }

        if (!options.data) {
            options.data = { download: 1 };
        }
        else {
            options.data.download = 1;
        }

        route += '?';
        for (let index in options.data) {
            let dataGet = options.data[index] === null ? '' : options.data[index];
            dataGet = dataGet === undefined ? '' : dataGet;

            if (Array.isArray(dataGet)) {
                for (let index1 in dataGet) {
                    route += index + "[]=" + dataGet[index1] + "&";
                }
            }
            else {
                if (dataGet || dataGet === false) route += index + "=" + dataGet + "&";
            }
        }
        route = route.slice(0, -1);

        for (let key in options.params) {
            let regEx = new RegExp('{' + key + '}', 'gi');
            route = route.replace(regEx, options.params[key]);
        }

        generateLogs(host, route);
        axios.get(host + route, { withCredentials: true, responseType: 'blob' })
            .then(response => new File([response.data], options.filename))
            .then(response => {
                const url = window.URL.createObjectURL(response);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = options.filename;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                callback({ status: 200 });
            })
            .catch(err => {
                console.log(route);
                console.log(err);
                if (err.response.status === 401) {
                    dispatch(AuthenticationLogout())
                }
                else {
                    if (err.response.data.status === 403 && err.response.data.error.message) {
                        dispatch(
                            SnackbarOpen({
                                text: err.response.data.error.message,
                                variant: 'error',
                            })
                        );
                    }
                    console.log(err.response.data);
                }
            });
    };
    this.cancelAll = function () {
        currentCall = {};
    };

    function generateLogs(reqHost, reqRoute, reqData = null) {
        if (
            !reqRoute.includes('learner') &&
            reqRoute !== routeApi.authentication_login
        ) {
            const payload = reqData
            const data = {
                endPoint: reqRoute,
                payload: payload
            }
            axios.post(reqHost + '/administrator/platform-log/add', data, { withCredentials: true })
                .then(function (response) {
                })
                .catch(err => {
                    console.log('/admin-monitoring/platform-log/add');
                    console.log(err);
                    console.log(err.response.data.error.message);
                    if (err.response.status === 401) {
                        dispatch(AuthenticationLogout())
                    } else {
                        if (err.response.data.status === 403 && err.response.data.error.message) {
                            dispatch(
                                SnackbarOpen({
                                    text: err.response.data.error.message,
                                    variant: 'error',
                                })
                            );
                        }
                    }
                });
        }
    }
}

const api = new Api();

export default api;