import React, { useState, useEffect } from 'react';
import { Switch, Route, useHistory } from "react-router-dom";
import Sidebar from './Component/Sidebar';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { IconButton, Tooltip } from '@mui/material';
import { AuthenticationLogout } from '../../Action/AuthenticationAction';
import { useDispatch, useSelector } from 'react-redux';
import Api from '../../Api';
import { makeStyles } from '@mui/styles';
import LoadingViewComponent from '../../Component/LoadingViewComponent';

export default function LayoutUser(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const history = useHistory();
    const isAdmin = props.location.pathname.startsWith('/authentication/admin');
    const [isAnimating, setIsAnimating] = useState(false);
    const [currentPath, setCurrentPath] = useState(props.location.pathname);
    const animationTimeoutRef = React.useRef(null);

    const logout = () => {
        dispatch(AuthenticationLogout());
        Api.get({ route: 'authentication_logout' }, () => {
        });
    };

    useEffect(() => {
        const unblock = history.block((location) => {
            if (isAnimating) {
                clearTimeout(animationTimeoutRef.current);
                setIsAnimating(false);
            }

            if (location.pathname !== currentPath) {
                setIsAnimating(true);
                animationTimeoutRef.current = setTimeout(() => {
                    setIsAnimating(false);
                    setCurrentPath(location.pathname);
                }, 4000);
            }

            return true;
        });

        return () => unblock();
    }, [history, isAnimating, currentPath]);


    return (
        <div style={{ display: 'grid', gridTemplateColumns: 'max-content auto' }}>
            {/* <== Sidebar */}
            {((!props.isMaintenance || authenticationReducer?.roles?.includes('ROLE_ADMIN'))) ? <Sidebar {...props} /> : <div></div>}
            {/* Sidebar ==> */}

            {/* <== View */}
            <div
                className='main'
                style={{
                    padding: '0',
                    height: '100%',
                    overflow: 'hidden scroll',
                }}
            >
                <Switch location={{ pathname: currentPath }}>
                    {props.routes.map((route) => (
                        <Route
                            key={'route_' + route.id}
                            path={route.path}
                            render={props => (
                                <route.view {...props} isAnimeLoading={isAnimating} routes={route.routes} />
                            )}
                        />
                    ))}
                </Switch>
                {/*!isAdmin &&
                    <LoadingViewComponent isAnimeLoading={isAnimating} {...props} />
                */}
            </div>
            {/* View ==> */}

            {authenticationReducer?.roles?.includes('ROLE_ADMIN') &&
                <Tooltip title="Déconnexion" aria-label="Déconnexion" arrow>
                    <IconButton className={classes.PowerSettings} component="span" onClick={logout}>
                        <p style={{ margin: 0 }}>Mode admin</p>
                        <PowerSettingsNewIcon style={{ color: '#FFF', fontSize: 16 }} />
                    </IconButton>
                </Tooltip>
            }
        </div>
    );
}

const useStyles = makeStyles({
    PowerSettings: {
        cursor: 'pointer',
        color: '#FFF !important',
        right: 0,
        bottom: 0,
        display: 'grid !important',
        padding: '10px 20px !important',
        position: 'fixed !important',
        background: '#4DA4C6 !important',
        alignItems: 'center !important',
        borderRadius: '14px 0 0 0 !important',
        gridTemplateColumns: 'auto max-content',
        fontSize: '15px !important',
        gap: 6,
        transition: 'all .4s',
        '&:hover': {
            background: '#3C839F !important',
        }
    }
});
