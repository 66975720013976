import React from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import momentFR from "moment-locale-fr";
import moment from 'moment';
import { getRouterLayout, getPathDefault, updateRouting } from "./Config/Router";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoadingViewComponent from "./Component/LoadingViewComponent";
import SnackbarComponent from "./Component/SnackbarComponent";
import './Css/App.css';
import './Css/style_font.css';
import './Css/parallax.css';
import './Css/sideBar.css';
import './Css/hero.css';
import Api from './Api';
import { AuthenticationLogout } from './Action/AuthenticationAction';
import WaveComponent from './Component/WaveComponent';

const VERSION = '1.0.0';
export let dispatch;

// Moment
moment.updateLocale('fr', momentFR);
moment.locale('fr');

function App() {
  dispatch = useDispatch();

  const [routerLayout, setRouterLayout] = React.useState(false);
  const authenticationReducer = useSelector(state => state.AuthenticationReducer);
  const loginPageReducer = useSelector(state => state.LoginPageReducer);
  const [isReady, setReady] = React.useState(false);
  const [isMaintenance, setMaintenance] = React.useState(false);
  const [versionBack, setVersionBack] = React.useState(false);
  const [isNotUpdate, setNotUpdate] = React.useState(false);

  // Security
  React.useEffect(() => {
    Api.get({ route: 'default' }, (response) => {
      if (!response.data.authenticated && authenticationReducer.email) dispatch(AuthenticationLogout());
      setMaintenance(response.data.maintenance);

      setVersionBack(response.data.version);

      setTimeout(() => {
        if (response.data.version === VERSION) {
          setReady(true);
        }
        else {
          setNotUpdate(true);
        }
      }, 1500);
    });

    setTimeout(() => window.location.reload(true), 21600000);
  }, []);

  React.useEffect(() => {
    updateRouting(authenticationReducer, loginPageReducer, isMaintenance);
    setRouterLayout(getRouterLayout());
  }, [authenticationReducer, loginPageReducer, isMaintenance]);

  // Catch incoming url
  React.useEffect(() => {
    localStorage.setItem('urlOrigin', window.location.pathname);
  }, []);

  return (
    <>
      {(isReady && routerLayout) &&
        <>
          <BrowserRouter>
            <Switch>
              {
                Object.keys(routerLayout).map((keyLayout) => (
                  routerLayout[keyLayout].map((route) => {
                    return <Route exact={true} key={'layout_' + route.id} path={route.path} render={props => <route.layout {...props} isMaintenance={isMaintenance} routes={routerLayout[keyLayout]} />} />
                  })
                ))
              }
              <Route component={() => <Redirect to={getPathDefault()} />} />
            </Switch>
          </BrowserRouter>
          <SnackbarComponent />
        </>
      }
      <WaveComponent />
    </>
  );
}

export default App;
