import { amber, green } from '@mui/material/colors';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import PropTypes from 'prop-types';
import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import WarningIcon from '@mui/icons-material/Warning';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { useSelector } from "react-redux";

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const useStyles1 = makeStyles(theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: 'red',
    },
    info: {
        backgroundColor: 'blue',
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: 10,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));

function MySnackbarContentWrapper(props) {
    const classes = useStyles1();
    const { className, message, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={clsx(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon className={clsx(classes.icon, classes.iconVariant)} /> {message}
                </span>
            }
            action={[
                <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
                    <CloseIcon className={classes.icon} />
                </IconButton>,
            ]}
            {...other}
        />
    );
}

MySnackbarContentWrapper.propTypes = {
    className: PropTypes.string,
    message: PropTypes.string,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
};

let timeoutClose = false;

export default function SnackbarComponent() {
    const snackbarReducer = useSelector(state => state.SnackbarReducer);
    const [snackbar, setSnackbar] = React.useState(false);
    const [isOpen, setOpen] = React.useState(false);

    React.useEffect(() => {
        setSnackbar({ ...snackbarReducer });
        setOpen(snackbarReducer.isOpen);

        if (timeoutClose) clearTimeout(timeoutClose)
        timeoutClose = setTimeout(() => {
            setOpen(false);
        }, snackbarReducer.duration);

    }, [snackbarReducer]);

    return (
        <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={isOpen}>
            <MySnackbarContentWrapper
                onClose={() => setOpen(false)}
                variant={snackbar.variant}
                message={snackbar.text}
            />
        </Snackbar>
    );
}

