import React from "react";
import { gsap } from "gsap";

const WaveComponent = () => {
    React.useEffect(() => {
        const wave = document.querySelector("#wave");
        const shape2 =
            "M469.539032,263.986786H-0.000001L0,229.890961c310.649475,58.156982,255.61113-98.5,469.539032-65.062302V263.986786z";
        const shape3 =
            "M469.539032,263.986786H-0.000001L0,0c226.11113,0,182.887283-0.414484,469.539032,0V263.986786z";

        // Animation avec GSAP
        gsap.timeline({ repeat: 0, repeatDelay: 1 })
            .to(wave, { duration: 0.8, attr: { d: shape2 }, ease: "power2.in" })
            .to(wave, { duration: 0.8, attr: { d: shape3 }, ease: "power2.out", fill: "#302F3B" });
    }, []);

    return (
        <div style={{ top: 0, left: 0, width: "100%", height: "100%", overflow: "hidden", position: "absolute", zIndex: -1000 }}>
            <svg
                className="wave"
                viewBox="0 0 469.539032 263.986786"
                preserveAspectRatio="none"
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                }}
            >
                <path
                    id="wave"
                    fill="#908EA2"
                    d="M469.539032,263.986786H-0.000001L0,263.557617c66.11113,0.429169,351.088104,0.429169,469.539032,0.208344V263.986786z"
                />
            </svg>
        </div>
    );
}
export default WaveComponent;