const AuthenticationReducer = (state = {}, action) => {
    switch (action.type) {
        case 'AuthenticationLogin':
            return {
                ...state,
                id: action.data.id,
                email: action.data.email,
                firstname: action.data.firstname,
                lastname: action.data.lastname,
                roles: action.data.roles,
            };
        case 'AuthenticationLogout':
            return {};
        case 'UpdateAuthentication':
            return {
            ...state,
            [action.data.name]: action.data.value
        };
        default:
            return state
    }
};

export default AuthenticationReducer;
