import React from 'react';
import { makeStyles } from '@mui/styles';
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import UseAnimations from "react-useanimations";
import visibility from "react-useanimations/lib/visibility";

function TextFieldComponent(props = {
    name: '',
    password: false,
    disabled: false,
    multiline: false,
    onSubmit: () => {
    },
    handler: {
        name: '',
        label: '',
        textHelper: '',
        type: '',
        defaultValue: '',
        value: '',
        error: '',
        options: {}
    }
}) {
    const classes = useStyles();
    const data = props.handler.form[props.name];
    const [value, setValue] = React.useState('');
    const [error, setError] = React.useState(data.error);
    const [isFocus, setFocus] = React.useState(false);
    const disabled = data.loading || data.disabled;
    const [showPassword, setShowPassword] = React.useState(props.password);

    const onChange = (event) => {
        let newValue = event.target.value ?? '';
        let options = data.options ?? {};
        let type = data.type;

        if (newValue) {
            if (type === 'integer') {
                if ((newValue.toString().match(/^-?\d*$/i) === null)) {
                    newValue = value;
                }
            } else if (type === 'float') {
                if ((newValue.toString().match(/^-?[0-9]*\.?[0-9]*$/i) === null)) {
                    newValue = value;
                }
            }

            if (options.max) {
                let length = (type === 'text') ? newValue.toString().length : newValue;
                if (!(options.max && length < options.max)) {
                    newValue = value;
                }
            }

            if (options.sup) {
                let length = (type === 'text') ? newValue.toString().length : newValue;
                if (!(options.sup && length <= options.sup)) {
                    newValue = value;
                }
            }
        }

        if (props.noSpace) {
            newValue = newValue.replace(/\s/g, '');
        }

        setValue(newValue);
        if (props.onChange) {
            props.onChange(newValue);
        }

        if (!isFocus) {
            let newForm = { ...props.handler.form };
            newForm[props.name].value = newValue;
            newForm[props.name].error = props.handler.getErrorByField(newForm[props.name]);
            setError(newForm[props.name].error);
            props.handler.set(newForm);
        }
    }
    const onBlur = () => {
        setFocus(false);
        let newForm = { ...props.handler.form };
        newForm[props.name].value = value;
        newForm[props.name].error = props.handler.getErrorByField(newForm[props.name]);
        setError(newForm[props.name].error);
        props.handler.set(newForm);
    }
    const onFocus = () => {
        setFocus(true);
    }

    React.useEffect(() => {
        setError(props.handler.form[props.name].error);
    }, [props.handler.form[props.name]].error);
    React.useEffect(() => {
        if (!props.handler.form[props.name].value && value || props.handler.form[props.name].value && props.handler.form[props.name].value !== value) {
            setValue(props.handler.form[props.name].value);
        }
    }, [props.handler.form[props.name].value]);

    return (
        <TextField
            id={props.name}
            type={showPassword ? 'password' : 'text'}
            className={`
                ${classes.textField}
                ${isFocus ? classes.textFieldFocus : error ? classes.textFieldError : value && !(disabled || props.disabled) ? classes.textFieldValid : ''}
            `}
            label={data.label && data.label + ((data.options && data.options.validation && data.options.validation.indexOf('required') > -1) ? ' *' : '')}
            value={value}
            helperText={error && !isFocus ? error : data.textHelper}
            variant="standard"
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            disabled={disabled || props.disabled}
            multiline={props.multiline}
            onKeyDown={(e) => {
                if (props.onSubmit && e.key === 'Enter') {
                    onBlur();
                    props.onSubmit();
                }
            }}
            InputProps={{
                endAdornment: props.password && (
                    <InputAdornment position="end" disabled={disabled || props.disabled}>
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                            disabled={disabled || props.disabled}
                        >
                            <UseAnimations reverse={showPassword} animation={visibility} size={25} disabled={disabled || props.disabled} />
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    );
}

const useStyles = makeStyles({
    textField: {
        width: '100%',
        '& input': {
            padding: '10px 14px',
            fontSize: 13
        },
        '& .MuiOutlinedInput-root': {
            fontSize: 13
        },
        '& .MuiInputLabel-outlined': {
            transform: 'translate(13px, 10px) scale(1)',
            fontSize: 13
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(15px, -4px) scale(0.75)',
        },
        '& .MuiFormHelperText-root': {
            margin: '2px 14px',
            fontSize: 9,
            minHeight: 9,
            lineHeight: '9px'
        },
        '& fieldset': {
            borderWidth: '1px !important'
        }
    },
    textFieldFocus: {
        '& .MuiFormHelperText-root': {
            color: '#3F51BF'
        }
    },
    textFieldValid: {
        '& fieldset': {
            borderColor: '#008000 !important'
        },
        '& .MuiFormHelperText-root': {
            color: '#008000'
        },
        '& label': {
            color: '#008000'
        }
    },
    textFieldError: {
        '& fieldset': {
            borderColor: '#982525 !important'
        },
        '& .MuiFormHelperText-root': {
            color: '#982525'
        },
        '& label': {
            color: '#982525'
        }
    }
});

export default TextFieldComponent;
