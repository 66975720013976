import React from 'react';
import { SymbolTitreExpIcon, LogoArchiIcon, LogoInfoIcon, LogoDevIcon, FaceBookIcon, InstagramIcon, LinkedInIcon, GitHubIcon, TwitterIcon } from '../../Component/IconComponent';
import AnimeSvgComponent from "./Component/AnimeSvgComponent";
import ParallaxLayer from './Component/ParallaxLayer';
import { LoadingView } from '../../Action/LoadingViewAction';
import { useDispatch } from 'react-redux';

const About = (props) => {
    const dispatch = useDispatch();
    const [offsetsY, setOffsetsY] = React.useState([]);
    const parallaxRefs = React.useRef([React.createRef(), React.createRef()]);
    React.useEffect(() => {
        const main = document.querySelector('.main');
        const handleScroll = () => {
            const newOffsetsY = parallaxRefs.current.map((ref) => {
                if (ref.current) {
                    const rect = ref.current.getBoundingClientRect();
                    const scrollPosition = main.scrollY + main.innerHeight;
                    const elementTop = rect.top + main.scrollY;

                    // Calcul du défilement uniquement si le bloc est visible dans la fenêtre d'affichage
                    if (scrollPosition >= elementTop && main.scrollY <= elementTop + rect.height) {
                        return main.scrollY - elementTop;
                    }

                    // Garde la position finale
                    return main.scrollY < elementTop ? 0 : rect.height;
                }
                return 0;
            });

            setOffsetsY(newOffsetsY);
        };

        main.addEventListener('scroll', handleScroll);
        return () => main.removeEventListener('scroll', handleScroll);
    }, []);

    React.useEffect(() => {
        dispatch(
            LoadingView({
                isOpen: false
            })
        );
    }, []);

    return (
        <>
            <div ref={parallaxRefs.current[0]} className="parallax-container">
                <ParallaxLayer image={'/asset/about/images/image11.png'} speed={0} offsetsY={offsetsY[0]} />
                <ParallaxLayer image={'/asset/about/images/image9.png'} speed={0.1} offsetsY={offsetsY[0]} />
                <ParallaxLayer image={'/asset/about/images/image7.png'} speed={0.5} offsetsY={offsetsY[0]} />
                <ParallaxLayer image={'/asset/about/images/image6.png'} speed={0.4} offsetsY={offsetsY[0]} />
                <ParallaxLayer image={'/asset/about/images/image5.png'} speed={0.3} offsetsY={offsetsY[0]} />
                <ParallaxLayer image={'/asset/about/images/image4.png'} speed={0.2} offsetsY={offsetsY[0]} />
                <ParallaxLayer image={'/asset/about/images/image3.png'} speed={0.09} offsetsY={offsetsY[0]} />
                <ParallaxLayer image={'/asset/about/images/image2.png'} speed={0.07} offsetsY={offsetsY[0]} />
                <ParallaxLayer image={'/asset/about/images/image1_bottom.png'} speed={0} offsetsY={offsetsY[0]} />
                <ParallaxLayer image={'/asset/about/images/image1_LR.png'} speed={0} offsetsY={offsetsY[0]} />
                <ParallaxLayer image={'/asset/about/images/image1_top.png'} speed={0} offsetsY={offsetsY[0]} />
            </div>
            <div className='blocDescriptParcour'>
                <div className='blocDescription'>
                    <img src="/asset/about/images/ben.jpg" className="photo" />
                    <div id="description">
                        <h1 className="nom_prenom">Benyamine Maoulida</h1>
                        <p className="age">27 ans</p>
                        <p className="ma_description">
                            Passionné par le développement web et les jeux vidéo, j'ai acquis une solide expérience en développement front-end, intégration web, webdesign, et graphisme. Mon enthousiasme pour les nouvelles tendances et technologies me permet de créer des expériences en ligne exceptionnelles. Ouvert à de nouvelles opportunités, je suis prêt à relever des défis passionnants. Contactez-moi pour discuter de projets ou collaborer, je suis convaincu que mon expertise contribuera grandement à la réalisation de projets web attrayants et fonctionnels.
                        </p>
                    </div>
                    <div className='blocReseaux'>
                        <a href='#'><FaceBookIcon style={{ height: '90%', width: '90%', margin: 'auto', }} /></a>
                        <a href='#'><InstagramIcon style={{ height: '100%', width: '100%' }} /></a>
                        <a href='#'><LinkedInIcon style={{ height: '100%', width: '100%' }} /></a>
                        <a href='#'><GitHubIcon style={{ height: '100%', width: '100%' }} /></a>
                        <a href='#'><TwitterIcon style={{ height: '100%', width: '100%' }} /></a>
                    </div>
                </div>
                <div className='blocParcour'>
                    <div className='blocText'>
                        <AnimeSvgComponent svg={SymbolTitreExpIcon} classStyle={'symbolRight'} />
                        <h1 className="mesExper">Mon Parcours</h1>
                        <AnimeSvgComponent svg={SymbolTitreExpIcon} classStyle={'symbolLeft'} />
                    </div>
                    <div className='detailParcour'>
                        <div></div>
                        <div className="etude_exper">
                            <div className="bloc_form">
                                <div className="ac_p text_donner">
                                    <p className="date">2014-2015</p>
                                    <p className="lieu">Lycée Denis Diderot Marseille, France</p>
                                    <p className="nom_formation">Baccalauréat Sciences Technologie De l'Industrie et du Développement Durable.</p>
                                </div>
                                <div className="ac_p logm"><AnimeSvgComponent svg={LogoArchiIcon} classStyle={'logo_exper1'} /></div>
                            </div>
                            <div className="bloc_form">
                                <div className="ac_p1 logm"><LogoInfoIcon classStyle='logo_exper1' /></div>
                                <div className="ac_p1 text_donner">
                                    <p className="date">2015-2017</p>
                                    <p className="lieu">Faculté ST Charles Marseille, France</p>
                                    <p className="nom_formation">Licence1 Maths-Informatique(non aquis).</p>
                                </div>
                            </div>
                            <div className="bloc_form">
                                <div className="ac_p text_donner">
                                    <p className="date">2018-2020</p>
                                    <p className="lieu">Grand ecole du numérique Marseille, France</p>
                                    <p className="nom_formation">Formation Simplon Coder et déployer une application web simple.</p>
                                </div>
                                <div className="ac_p logm"><LogoDevIcon classStyle='logo_exper1' /></div>
                            </div>
                            <div className="bloc_form">
                                <div className="ac_p1 logm"><LogoDevIcon classStyle='logo_exper1' /></div>
                                <div className="ac_p1 text_donner">
                                    <p className="date">2021</p>
                                    <p className="lieu">SantéFormapro<br /> Marseille, France</p>
                                    <p className="nom_formation"></p>
                                    <p className="nom_formation"></p>
                                </div>
                            </div>
                        </div>
                        <div></div>
                    </div>
                </div>
            </div>
            <div ref={parallaxRefs.current[1]} className="parallax-container">
                <ParallaxLayer image={'/asset/about/images/decor11.png'} speed={0} offsetsY={offsetsY[1]} />
                <ParallaxLayer image={'/asset/about/images/decor10.png'} speed={0.3} offsetsY={offsetsY[1]} />
                <ParallaxLayer image={'/asset/about/images/decor9.png'} speed={0.4} offsetsY={offsetsY[1]} />
                <ParallaxLayer image={'/asset/about/images/decor8.png'} speed={0.3} offsetsY={offsetsY[1]} />
                <ParallaxLayer image={'/asset/about/images/decor7.png'} speed={0.2} offsetsY={offsetsY[1]} />
                <ParallaxLayer image={'/asset/about/images/decor6.png'} speed={0.2} offsetsY={offsetsY[1]} />
                <ParallaxLayer image={'/asset/about/images/decor5.png'} speed={0.2} offsetsY={offsetsY[1]} />
                <ParallaxLayer image={'/asset/about/images/decor4.png'} speed={0.005} offsetsY={offsetsY[1]} />
                <ParallaxLayer image={'/asset/about/images/decor3.png'} speed={0} offsetsY={offsetsY[1]} />
                <ParallaxLayer image={'/asset/about/images/decor2.png'} speed={0} offsetsY={offsetsY[1]} />
                <ParallaxLayer image={'/asset/about/images/decor1.png'} speed={0} offsetsY={offsetsY[1]} />
            </div>
            <div className='blocPortfolio'></div>
            <div className='blocCoordonner'></div>
        </>
    );
}
export default About;