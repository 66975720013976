import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import gsap from 'gsap';  // Import GSAP 3
import { getRoutePathname } from '../../../Config/Router';

const Sidebar = (props) => {
  const [activeTray, setActiveTray] = useState(false);
  const trayRef = useRef(null);
  const svgRef = useRef(null);
  const hamburgerMenuRef = useRef(null);
  const lineRef = useRef(null);
  const stageRef = useRef(null);

  useEffect(() => {
    // Animation d'initialisation
    gsap.from(trayRef.current, { x: -400, duration: 1, ease: 'power2.out' });
    gsap.to(lineRef.current, {
      attr: { d: "M0,0 L0,0 C50,250,50,750,0,1000 L0,1000 L0,0 Z" },
      duration: 0.5,
      delay: 0.25,
      ease: 'power0.inOut'
    });
    gsap.to(lineRef.current, {
      attr: { d: "M0,0 L0,0 C0,0 0,1000 0,1000 L0,1000 L0,0 Z" },
      duration: 0.25,
      delay: 0.75,
      ease: 'power0.inOut'
    });
    gsap.to(hamburgerMenuRef.current, {
      scaleX: 1,
      transformOrigin: '0 50%',
      duration: 0.25,
      delay: 0.75,
      ease: 'power0.inOut'
    });
  }, []);

  const handleMouseMove = (event) => {
    const stage = stageRef.current.getBoundingClientRect();
    const percentMousePosY = Math.floor(((event.clientY - stage.top) / stage.height) * 100);
    const percentToSVGUnits = percentMousePosY * 10;

    if (!activeTray) {
      gsap.to(hamburgerMenuRef.current, {
        scaleX: 1.4,
        transformOrigin: '0 50%',
        duration: 1.5,
        ease: 'elastic.out(1, 0.75)'
      });
    }

    gsap.to(lineRef.current, {
      attr: { d: `M0,0 L0,0 C50,${percentToSVGUnits},50,${percentToSVGUnits + 250},0,1000 L0,1000 L0,0 Z` },
      duration: 1.5,
      ease: 'elastic.out(1, 0.75)'
    });
  };

  const handleMouseOut = () => {
    // Supprimez les animations en cours
    gsap.killTweensOf([lineRef.current, hamburgerMenuRef.current]);

    // Réinitialiser la courbe du SVG
    gsap.to(lineRef.current, {
      attr: { d: "M0,0 L0,0 C0,0 0,1000 0,1000 L0,1000 L0,0 Z" },
      duration: 0.25,
      ease: 'power1.inOut'
    });

    // Réinitialiser l'échelle du menu hamburger
    gsap.to(hamburgerMenuRef.current, {
      scaleX: 1,
      transformOrigin: '0 50%',
      duration: 0.25,
      ease: 'power1.inOut'
    });
  };


  const handleClick = () => {
    if (!activeTray) {
      gsap.to(trayRef.current, { x: 264, duration: 0.3, ease: 'power2.out' });
      gsap.from("#nav a", {
        duration: 0.7,
        x: -15,
        opacity: 0,
        delay: 0.1,
        ease: 'power2.out',
        stagger: 0.1
      });
      gsap.to(hamburgerMenuRef.current.querySelector('#hamburger-middle'), { opacity: 0, scale: 0, duration: 0.7, ease: 'power2.out' });
      gsap.to(hamburgerMenuRef.current.querySelector('#hamburger-top'), { y: 7, rotation: 50, duration: 0.7, ease: 'power2.out' });
      gsap.to(hamburgerMenuRef.current.querySelector('#hamburger-bottom'), { y: -11, rotation: -50, duration: 0.7, ease: 'power2.out' });
      setActiveTray(true);
    } else {
      gsap.to(trayRef.current, { x: 0, duration: 0.5, ease: 'back.out(1.7)' });
      gsap.to(hamburgerMenuRef.current.querySelector('#hamburger-middle'), { opacity: 1, scale: 1, duration: 0.7, ease: 'power2.out' });
      gsap.to(hamburgerMenuRef.current.querySelector('#hamburger-top'), { y: 0, rotation: 0, duration: 0.7, ease: 'power2.out' });
      gsap.to(hamburgerMenuRef.current.querySelector('#hamburger-bottom'), { y: 0, rotation: 0, duration: 0.7, ease: 'power2.out' });
      setActiveTray(false);
    }
    handleMouseOut();
  };

  return (
    <div id="wrapper" ref={stageRef} style={{ zIndex: 1000 }}>
      <div id="tray" ref={trayRef} onMouseMove={handleMouseMove} onMouseOut={handleMouseOut}>
        <ul id="nav">
          <li><a onClick={() => { handleClick(); props.history.push(getRoutePathname('home')) }}>Home</a></li>
          <li><a onClick={() => { handleClick(); props.history.push(getRoutePathname('arts_book')) }}>ArtsBook</a></li>
          <li><a onClick={() => { handleClick(); props.history.push(getRoutePathname('about')) }}>About</a></li>
        </ul>
        <a id="tray-button" onClick={handleClick}>
          <div id="svg-container">
            <svg id="bending-line" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 1000" preserveAspectRatio="none">
              <path id="line" ref={lineRef} d="M0,0 L0,0 C0,250 0,750 0,1000 L0,1000 L0,0 Z"></path>
            </svg>
          </div>
          <div id="hamburger-menu" ref={hamburgerMenuRef}>
            <div id="hamburger-top" className="hamburger-line"></div>
            <div id="hamburger-middle" className="hamburger-line"></div>
            <div id="hamburger-bottom" className="hamburger-line"></div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Sidebar;
