import React, { useEffect, useRef } from 'react';
import { fullAnimation, fullAnimationRemove } from './AnimeCardComponent';

const Hero = (props) => {
  const cardRef = useRef(null);

  useEffect(() => {
    if (!props.isAnimeLoading) {
      // Initialiser l'animation lorsque le composant est monté
      fullAnimation().init();
    } else {
      // Si l'animation est en train de charger, inverser l'animation
      fullAnimationRemove().init();
    }
  }, [props.isAnimeLoading]);

  useEffect(() => {
    const card = cardRef.current;
    const handleMouseMove = (e) => {
      const rect = card.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;

      const mouseX = e.clientX;
      const mouseY = e.clientY;

      const deltaX = (mouseX - centerX) / (rect.width / 2);
      const deltaY = (mouseY - centerY) / (rect.height / 2);

      // Appliquer les rotations en fonction de la position de la souris
      card.style.transform = `perspective(500px) rotateX(${deltaY * 5}deg) rotateY(${deltaX * -5}deg) scaleX(1) scaleY(1) rotateZ(-1deg)`;
    };

    const handleMouseLeave = () => {
      // Réinitialiser la transformation lorsque la souris quitte la carte
      card.style.transform = 'scaleX(1) scaleY(1) perspective(500px) rotateY(-15deg) rotateX(8deg) rotateZ(-1deg)';
    };

    card.addEventListener('mousemove', handleMouseMove);
    card.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      card.removeEventListener('mousemove', handleMouseMove);
      card.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  return (
    <section className="hero">
      <div className="container">
        <div className="hero-inner">
          <div className="hero-figure anime-element">
            <svg className="placeholder" width="528" height="396" viewBox="0 0 528 396">
              <rect width="528" height="396" style={{ fill: 'transparent' }} />
            </svg>
            <div className="hero-figure-box hero-figure-box-01" data-rotation="45deg"></div>
            <div className="hero-figure-box hero-figure-box-02" data-rotation="-45deg"></div>
            <div className="hero-figure-box hero-figure-box-03" data-rotation="0deg"></div>
            <div className="hero-figure-box hero-figure-box-04" data-rotation="-135deg"></div>
            <div className="hero-figure-box hero-figure-box-05" ref={cardRef} style={{
              transition: 'transform 0.1s ease-out',
              willChange: 'transform',
              border: '2px solid #FFF', overflow: 'hidden'
            }}>
              <img src='/asset/home/6.png' style={{ position: 'absolute', width: '100%' }} />
            </div>
            <div className="hero-figure-box hero-figure-box-06" style={{ opacity: 0, display: 'none' }}></div>
            <div className="hero-figure-box hero-figure-box-07" data-rotation="-22deg" style={{ background: 'rgba(0, 191, 251, 0.32)' }}></div>
            <div className="hero-figure-box hero-figure-box-08" data-rotation="-22deg"></div>
            <div className="hero-figure-box hero-figure-box-09" data-rotation="-52deg"></div>
            <div className="hero-figure-box hero-figure-box-10" data-rotation="-50deg"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
