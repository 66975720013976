import React from 'react';

const ParallaxLayer = ({ image, speed, offsetsY }) => {
    const translateY = offsetsY * speed;
    return (
        <div
            style={{
                backgroundImage: `url(${image})`,
                transform: `translateY(${translateY}px)`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: -1,
            }}
        ></div>
    );
};

export default ParallaxLayer;
