import { Box } from "@mui/system";
import { makeStyles } from '@mui/styles';
import gsap from "gsap";
import React from "react";

const Maintenance = (props) => {
    const classes = useStyles();
    return (
        <Box style={{ width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
            <div className={classes.body}>
                <div className={classes.popup}>
                    <div className={classes.textContainer}>
                        <svg id="OBJECTS" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 450 300.4">
                            <g>
                                <g>
                                    <path class="st3" d="M352.6,21.3s0-3.5,3.5-3.5h20.8s3.5,0,3.5,3.5v5.2s0,3.5-3.5,3.5h-20.8s-3.5,0-3.5-3.5v-5.2Z" />
                                    <path class="st3" d="M333.5,42.1s0-3.5,3.5-3.5h20.8s3.5,0,3.5,3.5v5.2s0,3.5-3.5,3.5h-20.8s-3.5,0-3.5-3.5v-5.2Z" />
                                    <path class="st3" d="M369.9,42.1s0-3.5,3.5-3.5h20.8s3.5,0,3.5,3.5v5.2s0,3.5-3.5,3.5h-20.8s-3.5,0-3.5-3.5v-5.2Z" />
                                </g>
                                <g>
                                    <path class="st3" d="M48.7,83.3s0-3.5,3.5-3.5h20.8s3.5,0,3.5,3.5v5.2s0,3.5-3.5,3.5h-20.8s-3.5,0-3.5-3.5v-5.2Z" />
                                    <path class="st3" d="M29.6,104.1s0-3.5,3.5-3.5h20.8s3.5,0,3.5,3.5v5.2s0,3.5-3.5,3.5h-20.8s-3.5,0-3.5-3.5v-5.2Z" />
                                    <path class="st3" d="M66,104.1s0-3.5,3.5-3.5h20.8s3.5,0,3.5,3.5v5.2s0,3.5-3.5,3.5h-20.8s-3.5,0-3.5-3.5v-5.2Z" />
                                </g>
                                <path class="st3" d="M111.6,85.9s-8.9,0-8.9,8.9,8.9,8.9,8.9,8.9h53.4s8.9,0,8.9,8.9-8.9,8.9-8.9,8.9h-89s-8.9,0-8.9,8.9,8.9,8.9,8.9,8.9h62.3s8.9,0,8.9,8.9-8.9,8.9-8.9,8.9h-44.5s-8.9,0-8.9,8.9,8.9,8.9,8.9,8.9h71.2s8.9,0,8.9,8.9-8.9,8.9-8.9,8.9H49.2s-8.9,0-8.9,8.9,8.9,8.9,8.9,8.9h89s8.9,0,8.9,8.9-8.9,8.9-8.9,8.9h-62.3s-8.9,0-8.9,8.9,8.9,8.9,8.9,8.9h274.1s8.9,0,8.9-8.9-8.9-8.9-8.9-8.9h-17.8s-8.9,0-8.9-8.9,8.9-8.9,8.9-8.9h53.4s8.9,0,8.9-8.9-8.9-8.9-8.9-8.9h-80.1s-8.9,0-8.9-8.9,8.9-8.9,8.9-8.9h97.9s8.9,0,8.9-8.9-8.9-8.9-8.9-8.9h-80.1s-8.9,0-8.9-8.9,8.9-8.9,8.9-8.9h62.3s8.9,0,8.9-8.9-8.9-8.9-8.9-8.9h-80.1s-8.9,0-8.9-8.9,8.9-8.9,8.9-8.9h53.4s8.9,0,8.9-8.9-8.9-8.9-8.9-8.9H111.6Z" />
                            </g>
                            <g>
                                <path class="st3" d="M169.2,48.1v-6.6l-9.2-3.1c-.6-2.2-1.4-4.2-2.5-6.1l4.3-8.6-4.7-4.7-8.6,4.3c-1.9-1.1-4-2-6.1-2.5l-3-9.2h-6.6l-3,9.2c-2.2.6-4.2,1.4-6.1,2.5l-8.6-4.3-4.7,4.7,4.3,8.6c-1.1,1.9-2,4-2.5,6.1l-9.2,3.1v6.6l9.2,3.1c.6,2.2,1.4,4.2,2.5,6.1l-4.3,8.6,4.7,4.7,8.6-4.3c1.9,1.1,3.9,2,6.1,2.5l3,9.2h6.6l3-9.2c2.2-.6,4.2-1.4,6.1-2.5l8.6,4.3,4.7-4.7-4.3-8.6c1.1-1.9,2-4,2.5-6.1l9.2-3.1ZM136,55.6c-6,0-10.8-4.8-10.8-10.8s4.8-10.8,10.8-10.8,10.8,4.8,10.8,10.8-4.8,10.8-10.8,10.8Z" />
                                <g>
                                    <path class="st1" d="M140.4,79.5h-8.8l-3.1-9.4c-1.7-.5-3.4-1.2-5-2.1l-8.9,4.4-6.2-6.2,4.4-8.9c-.9-1.6-1.5-3.3-2.1-5l-9.4-3.1v-8.8l9.4-3.1c.5-1.7,1.2-3.4,2.1-5l-4.4-8.9,6.2-6.2,8.9,4.4c1.6-.9,3.3-1.5,5-2.1l3.1-9.4h8.8l3.1,9.4c1.7.5,3.4,1.2,5,2.1l8.9-4.4,6.2,6.2-4.4,8.9c.9,1.6,1.5,3.3,2.1,5l9.4,3.1v8.8l-9.4,3.1c-.5,1.7-1.2,3.4-2.1,5l4.4,8.9-6.2,6.2-8.9-4.4c-1.6.9-3.3,1.5-5,2.1l-3.1,9.4ZM133.8,76.5h4.5l3-8.9.8-.2c2-.5,4-1.3,5.8-2.4l.7-.4,8.4,4.2,3.2-3.2-4.2-8.4.4-.7c1-1.8,1.8-3.7,2.4-5.8l.2-.8,8.9-3v-4.5l-8.9-3-.2-.8c-.5-2-1.3-4-2.4-5.8l-.4-.7,4.2-8.4-3.2-3.2-8.4,4.2-.7-.4c-1.8-1-3.7-1.8-5.8-2.4l-.8-.2-3-8.9h-4.5l-3,8.9-.8.2c-2,.5-4,1.3-5.8,2.4l-.7.4-8.4-4.2-3.2,3.2,4.2,8.4-.4.7c-1,1.8-1.8,3.7-2.4,5.8l-.2.8-8.9,3v4.5l8.9,3,.2.8c.5,2,1.3,4,2.4,5.8l.4.7-4.2,8.4,3.2,3.2,8.4-4.2.7.4c1.8,1,3.7,1.8,5.8,2.4l.8.2,3,8.9Z" />
                                    <path class="st1" d="M136,57.1c-6.8,0-12.3-5.5-12.3-12.3s5.5-12.3,12.3-12.3,12.3,5.5,12.3,12.3-5.5,12.3-12.3,12.3ZM136,35.5c-5.1,0-9.3,4.2-9.3,9.3s4.2,9.3,9.3,9.3,9.3-4.2,9.3-9.3-4.2-9.3-9.3-9.3Z" />
                                </g>
                            </g>
                            <g>
                                <path class="st2" d="M269.2,288.8c0,3.7-3.7,3.7-3.7,3.7h-60.5s-3.7,0-3.7-3.7,9.3-11.1,9.3-11.1v-23.9h49.4v23.9s9.3,7.4,9.3,11.1Z" />
                                <path class="st2" d="M235.2,106.4h86.4c14.9,0,14.9,14.9,14.9,14.9v117.6s0,14.9-14.9,14.9h-86.4s-86.4,0-86.4,0c-14.9,0-14.9-14.9-14.9-14.9v-117.6s0-14.9,14.9-14.9h86.4Z" />
                                <rect class="st3" x="151.3" y="123.7" width="167.9" height="112.7" />
                                <line class="st2" x1="210.5" y1="277.6" x2="259.9" y2="277.6" />
                                <g>
                                    <path class="st1" d="M265.5,294h-60.5c-2.1,0-5.2-1.4-5.2-5.2s6.8-9.8,9.3-11.9v-24.7h52.4v24.7c2.4,2,9.3,8,9.3,11.9s-3.1,5.2-5.2,5.2ZM212,255.2v23.1l-.6.5c-4.2,3.4-8.7,8.1-8.7,10s1.8,2.2,2.2,2.2h60.5c.7,0,2.2-.3,2.2-2.2s-4.5-6.6-8.7-10l-.6-.5v-23.1h-46.4Z" />
                                    <path class="st1" d="M321.6,255.2h-172.8c-16.2,0-16.4-16.2-16.4-16.4v-117.6c0-.2.2-16.4,16.4-16.4h172.8c16.2,0,16.4,16.2,16.4,16.4v117.6c0,.2-.2,16.4-16.4,16.4ZM148.8,107.9c-13.2,0-13.4,12.8-13.4,13.4v117.6c0,.5.2,13.4,13.4,13.4h172.8c13.2,0,13.4-12.8,13.4-13.4v-117.6c0-.5-.2-13.4-13.4-13.4h-172.8Z" />
                                    <path class="st1" d="M320.7,237.9h-170.9v-115.7h170.9v115.7ZM152.8,234.9h164.9v-109.7h-164.9v109.7Z" />
                                    <rect class="st1" x="210.5" y="276.1" width="49.4" height="3" />
                                    <g>
                                        <path class="st2" d="M268.4,183.4v-6.6l-9.2-3c-.6-2.2-1.4-4.2-2.5-6.1l4.3-8.6-4.7-4.7-8.6,4.3c-1.9-1.1-3.9-2-6.1-2.5l-3-9.1h-6.6l-3,9.1c-2.2.6-4.2,1.4-6.1,2.5l-8.6-4.3-4.7,4.7,4.3,8.6c-1.1,1.9-2,3.9-2.5,6.1l-9.2,3v6.6l9.2,3c.6,2.2,1.4,4.2,2.5,6.1l-4.3,8.6,4.7,4.7,8.6-4.3c1.9,1.1,4,2,6.1,2.5l3,9.2h6.6l3-9.2c2.2-.6,4.2-1.4,6.1-2.5l8.6,4.3,4.7-4.7-4.3-8.6c1.1-1.9,2-4,2.5-6.1l9.2-3ZM235.2,190.8c-6,0-10.8-4.8-10.8-10.8s4.8-10.8,10.8-10.8,10.8,4.8,10.8,10.8-4.8,10.8-10.8,10.8Z" />
                                        <path class="st1" d="M239.6,214.7h-8.8l-3.1-9.4c-1.7-.5-3.4-1.2-5-2.1l-8.9,4.4-6.2-6.2,4.4-8.9c-.9-1.6-1.5-3.3-2.1-5l-9.4-3.1v-8.8l9.4-3.1c.5-1.7,1.2-3.4,2.1-5l-4.4-8.9,6.2-6.2,8.9,4.4c1.6-.9,3.3-1.5,5-2.1l3.1-9.4h8.8l3.1,9.4c1.7.5,3.4,1.2,5,2.1l8.9-4.4,6.2,6.2-4.4,8.9c.9,1.6,1.5,3.3,2.1,5l9.4,3.1v8.8l-9.4,3.1c-.5,1.7-1.2,3.4-2.1,5l4.4,8.9-6.2,6.2-8.9-4.4c-1.6.9-3.3,1.5-5,2.1l-3.1,9.4ZM233,211.7h4.5l3-8.9.8-.2c2-.5,4-1.3,5.8-2.4l.7-.4,8.4,4.2,3.2-3.2-4.2-8.4.4-.7c1-1.8,1.8-3.7,2.4-5.8l.2-.8,8.9-3v-4.5l-8.9-3-.2-.8c-.5-2-1.3-4-2.4-5.8l-.4-.7,4.2-8.4-3.2-3.2-8.4,4.2-.7-.4c-1.8-1-3.7-1.8-5.8-2.4l-.8-.2-3-8.9h-4.5l-3,8.9-.8.2c-2,.5-4,1.3-5.8,2.4l-.7.4-8.4-4.2-3.2,3.2,4.2,8.4-.4.7c-1,1.8-1.8,3.7-2.4,5.8l-.2.8-8.9,3v4.5l8.9,3,.2.8c.5,2,1.3,4,2.4,5.8l.4.7-4.2,8.4,3.2,3.2,8.4-4.2.7.4c1.8,1,3.7,1.8,5.8,2.4l.8.2,3,8.9Z" />
                                        <path class="st1" d="M235.2,192.3c-6.8,0-12.3-5.5-12.3-12.3s5.5-12.3,12.3-12.3,12.3,5.5,12.3,12.3-5.5,12.3-12.3,12.3ZM235.2,170.8c-5.1,0-9.3,4.2-9.3,9.3s4.2,9.3,9.3,9.3,9.3-4.2,9.3-9.3-4.2-9.3-9.3-9.3Z" />
                                    </g>
                                </g>
                            </g>
                            <g>
                                <path class="st2" d="M58.4,177.4s0-6.9,6.9-6.9h69.3s6.9,0,6.9,6.9v46.8s0,6.9-6.9,6.9h-69.3s-6.9,0-6.9-6.9v-46.8Z" />
                                <path class="st1" d="M115.1,187.2l2.7,1.3-13.9,29.5-2.7-1.3,13.9-29.5ZM93.1,189.2l-14,10.5c-.4.3-.6.7-.6,1.2s.2.9.6,1.2l14,10.5,1.8-2.4-12.4-9.3,12.4-9.3-1.8-2.4ZM143.1,177.4v46.8c0,2.9-1.8,8.4-8.4,8.4h-69.3c-2.9,0-8.4-1.8-8.4-8.4v-46.8c0-2.9,1.8-8.4,8.4-8.4h69.3c2.9,0,8.4,1.8,8.4,8.4ZM140.1,177.4c0-5.2-4.9-5.4-5.4-5.4h-69.3c-5.3,0-5.4,4.9-5.4,5.4v46.8c0,5.2,4.9,5.4,5.4,5.4h69.3c5.2,0,5.4-4.9,5.4-5.4v-46.8Z" />
                            </g>
                            <path class="st1" d="M423.7,271.2h-163.8v-3h163.8v3ZM210.5,271.1v-3s-183.7.2-183.7.2v3s183.7-.2,183.7-.2Z" />
                            <g>
                                <path class="st2" d="M369.3,82.4v46.8s0,6.9-6.9,6.9h-69.3s-6.9,0-6.9-6.9v-46.8s0-6.9,6.9-6.9h69.3s6.9,0,6.9,6.9Z" />
                                <path class="st3" d="M369.3,82.4v13.9h-83.2v-13.9s0-6.9,6.9-6.9h69.3s6.9,0,6.9,6.9Z" />
                                <path class="st1" d="M362.3,74h-69.3c-6.7,0-8.4,5.5-8.4,8.4v46.8c0,6.7,5.5,8.4,8.4,8.4h69.3c6.7,0,8.4-5.5,8.4-8.4v-46.8c0-6.7-5.5-8.4-8.4-8.4ZM367.8,94.8h-80v3h80v31.4c0,.6-.2,5.4-5.4,5.4h-69.3c-.6,0-5.4-.2-5.4-5.4v-46.8c0-.6.1-5.4,5.4-5.4h69.3c.6,0,5.4.2,5.4,5.4v12.4ZM360.3,85.9c0,1.8-1.5,3.2-3.2,3.2s-3.2-1.5-3.2-3.2,1.5-3.2,3.2-3.2,3.2,1.5,3.2,3.2ZM348.2,85.9c0,1.8-1.5,3.2-3.2,3.2s-3.2-1.5-3.2-3.2,1.5-3.2,3.2-3.2,3.2,1.5,3.2,3.2ZM336.1,85.9c0,1.8-1.5,3.2-3.2,3.2s-3.2-1.5-3.2-3.2,1.5-3.2,3.2-3.2,3.2,1.5,3.2,3.2Z" />
                            </g>
                            <g>
                                <path class="st2" d="M339.9,240.3l12.9,12.9c6.7-2.4,14.5-.9,20,4.5,6.1,6.1,7.2,15.4,3.3,22.6l-11-11h-7.7s0,7.7,0,7.7l11,11c-7.3,3.9-16.5,2.8-22.6-3.3-5.4-5.4-6.9-13.2-4.5-20l-12.9-12.9-12.9-12.9c-6.7,2.4-14.5.9-20-4.5-6.1-6.1-7.2-15.4-3.3-22.6l11,11h7.7s0-7.7,0-7.7l-11-11c7.3-3.9,16.5-2.8,22.6,3.3,5.4,5.4,6.9,13.2,4.5,20l12.9,12.9Z" />
                                <path class="st1" d="M359.2,291.9c-5.5,0-10.7-2.1-14.6-6-5.5-5.5-7.4-13.3-5.1-20.6l-24.5-24.5c-2,.6-4,.9-6.1.9-5.5,0-10.7-2.1-14.6-6-6.5-6.5-7.9-16.3-3.6-24.4l1-1.8,12,12h5.6v-5.6l-12-12,1.8-1c3-1.6,6.4-2.5,9.8-2.5,5.5,0,10.7,2.1,14.6,6,5.5,5.5,7.4,13.3,5.1,20.6l24.5,24.5c2-.6,4-.9,6.1-.9,5.5,0,10.7,2.1,14.6,6,6.5,6.5,7.9,16.3,3.6,24.4l-1,1.8-12-12h-5.6v5.6l12,12-1.8,1c-3,1.6-6.4,2.5-9.8,2.5ZM315.7,237.3l27.2,27.2-.3.9c-2.3,6.5-.7,13.5,4.1,18.4,3.3,3.3,7.8,5.2,12.5,5.2s4.5-.4,6.5-1.3l-10-10v-9.9h9.9l10,10c2.6-6.5,1.2-13.9-3.9-19-3.3-3.3-7.8-5.2-12.4-5.2s-4,.3-6,1l-.9.3-27.2-27.2.3-.9c2.3-6.5.7-13.5-4.1-18.4-3.3-3.3-7.8-5.2-12.5-5.2s-4.5.4-6.5,1.3l10,10v9.9h-9.9s-10-10-10-10c-2.6,6.5-1.2,13.9,3.9,19,3.3,3.3,7.7,5.2,12.4,5.2s4-.3,6-1l.9-.3Z" />
                            </g>
                            <g>
                                <path class="st1" d="M258,58.2l-2.6-2.6,2.6-2.6c.6-.6.6-1.5,0-2.1-.6-.6-1.5-.6-2.1,0l-2.6,2.6-2.6-2.6c-.6-.6-1.5-.6-2.1,0-.6.6-.6,1.5,0,2.1l2.6,2.6-2.6,2.6c-.6.6-.6,1.5,0,2.1.3.3.7.4,1.1.4s.8-.1,1.1-.4l2.6-2.6,2.6,2.6c.3.3.7.4,1.1.4s.8-.1,1.1-.4c.6-.6.6-1.5,0-2.1Z" />
                                <path class="st1" d="M57.9,254.8l-2.6-2.6,2.6-2.6c.6-.6.6-1.5,0-2.1s-1.5-.6-2.1,0l-2.6,2.6-2.6-2.6c-.6-.6-1.5-.6-2.1,0s-.6,1.5,0,2.1l2.6,2.6-2.6,2.6c-.6.6-.6,1.5,0,2.1s.7.4,1.1.4.8-.1,1.1-.4l2.6-2.6,2.6,2.6c.3.3.7.4,1.1.4s.8-.1,1.1-.4c.6-.6.6-1.5,0-2.1Z" />
                                <path class="st1" d="M412.4,134.7h-3.7v-3.7c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5,1.5v3.7h-3.7c-.8,0-1.5.7-1.5,1.5s.7,1.5,1.5,1.5h3.7v3.7c0,.8.7,1.5,1.5,1.5s1.5-.7,1.5-1.5v-3.7h3.7c.8,0,1.5-.7,1.5-1.5s-.7-1.5-1.5-1.5Z" />
                                <path class="st1" d="M407.2,19.8h-3.7v-3.7c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5,1.5v3.7h-3.7c-.8,0-1.5.7-1.5,1.5s.7,1.5,1.5,1.5h3.7v3.7c0,.8.7,1.5,1.5,1.5s1.5-.7,1.5-1.5v-3.7h3.7c.8,0,1.5-.7,1.5-1.5s-.7-1.5-1.5-1.5Z" />
                                <path class="st3" d="M385.1,241.6l2.6-2.6c.6-.6.6-1.5,0-2.1s-1.5-.6-2.1,0l-2.6,2.6-2.6-2.6c-.6-.6-1.5-.6-2.1,0s-.6,1.5,0,2.1l2.6,2.6-2.6,2.6c-.6.6-.6,1.5,0,2.1s.7.4,1.1.4.8-.1,1.1-.4l2.6-2.6,2.6,2.6c.3.3.7.4,1.1.4s.8-.1,1.1-.4c.6-.6.6-1.5,0-2.1l-2.6-2.6Z" />
                                <path class="st3" d="M284.8,34l2.6-2.6c.6-.6.6-1.5,0-2.1s-1.5-.6-2.1,0l-2.6,2.6-2.6-2.6c-.6-.6-1.5-.6-2.1,0-.6.6-.6,1.5,0,2.1l2.6,2.6-2.6,2.6c-.6.6-.6,1.5,0,2.1s.7.4,1.1.4.8-.1,1.1-.4l2.6-2.6,2.6,2.6c.3.3.7.4,1.1.4s.8-.1,1.1-.4c.6-.6.6-1.5,0-2.1l-2.6-2.6Z" />
                                <path class="st3" d="M46.1,154.6l2.6-2.6c.6-.6.6-1.5,0-2.1-.6-.6-1.5-.6-2.1,0l-2.6,2.6-2.6-2.6c-.6-.6-1.5-.6-2.1,0-.6.6-.6,1.5,0,2.1l2.6,2.6-2.6,2.6c-.6.6-.6,1.5,0,2.1.3.3.7.4,1.1.4s.8-.1,1.1-.4l2.6-2.6,2.6,2.6c.3.3.7.4,1.1.4s.8-.1,1.1-.4c.6-.6.6-1.5,0-2.1l-2.6-2.6Z" />
                                <path class="st3" d="M235.2,33.4c-3.8,0-6.9-3.1-6.9-6.9s3.1-6.9,6.9-6.9,6.9,3.1,6.9,6.9-3.1,6.9-6.9,6.9ZM235.2,22.6c-2.1,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9,3.9-1.7,3.9-3.9-1.7-3.9-3.9-3.9Z" />
                                <path class="st3" d="M390.9,91.1c-3.8,0-6.9-3.1-6.9-6.9s3.1-6.9,6.9-6.9,6.9,3.1,6.9,6.9-3.1,6.9-6.9,6.9ZM390.9,80.3c-2.1,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9,3.9-1.7,3.9-3.9-1.7-3.9-3.9-3.9Z" />
                                <path class="st1" d="M199.8,70.4c-3.8,0-6.9-3.1-6.9-6.9s3.1-6.9,6.9-6.9,6.9,3.1,6.9,6.9-3.1,6.9-6.9,6.9ZM199.8,59.6c-2.1,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9,3.9-1.7,3.9-3.9-1.7-3.9-3.9-3.9Z" />
                                <path class="st1" d="M408.7,230.4c-3.8,0-6.9-3.1-6.9-6.9s3.1-6.9,6.9-6.9,6.9,3.1,6.9,6.9-3.1,6.9-6.9,6.9ZM408.7,219.6c-2.1,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9,3.9-1.7,3.9-3.9-1.7-3.9-3.9-3.9Z" />
                            </g>
                        </svg>
                    </div>
                    <div className={classes.header}>EN MAINTENANCE</div>
                </div>
            </div>
        </Box>
    )
}

const useStyles = makeStyles({
    body: {
        perspective: '1200px',
        height: '100%',
        display: 'flex'
    },
    popup: {
        textAlign: 'center',
        margin: 'auto',
        backgroundColor: 'white',
        width: '500px',
        borderRadius: 10,
        padding: '30px 20px',
        transformStyle: 'preserve-3d',
        animation: '$rotateIn 0.75s ease-out 0s 1 forwards',
    },
    header: {
        display: 'block',
        textTransform: 'uppercase',
        color: '#302f3b',
        fontSize: '30px',
        fontWeight: 'bold',
    },
    textContainer: {
        minHeight: '130px',
        display: 'block',
        fontSize: '12px',
        color: '#666',
        '& h1': {
            fontSize: '28px',
            color: '#555',
            fontWeight: 'normal',
        },
    },
    '@keyframes rotateIn': {
        '0%': { opacity: 0, transform: 'rotateX(-90deg)' },
        '50%': { opacity: 1, transform: 'rotateX(10deg)' },
        '75%': { opacity: 1, transform: 'rotateX(-5deg)' },
        '100%': { opacity: 1, transform: 'rotateX(0deg)' },
    },
});

export default Maintenance;