import React from 'react';
import ReactDOM from 'react-dom/client'; // Utilisez 'react-dom/client' pour React 18
import './Css/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import Store from "./Store";

const appRoot = document.getElementById('root');
appRoot.setAttribute('class', 'notranslate');
appRoot.setAttribute('translate', 'no');

// Créez un root et utilisez `root.render` pour monter l'application
const root = ReactDOM.createRoot(appRoot); // Utilisez createRoot ici
root.render(
    <Provider store={Store.store}>
        <PersistGate loading={null} persistor={Store.persistor}>
            <App />
        </PersistGate>
    </Provider>
);

reportWebVitals();

