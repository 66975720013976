const LoadingViewReducer = (state = {}, action) => {
    switch (action.type) {
        case 'LoadingView':
            return {
                ...state,
                isOpen: action.isOpen,
                color: action.color,
                duration: action.duration
            };
        default:
            return state
    }
};

export default LoadingViewReducer;
