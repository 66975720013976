// Layouts
//import LayoutAdmin from "../Layout/LayoutAdmin/LayoutAdmin";
import LayoutUser from "../Layout/LayoutUser/LayoutUser";

// Views
import ArtsBook from "../View/ArtsBook/Artsbook";
import About from "../View/About/About";
import Home from "../View/Home/Home";

// Access
import Maintenance from "../View/Maintenance/Maintenance";
import Login from "../View/Authentication/Login/Login";

export const routing = {
    //ADMIN

    // USER
    home: { path: '/home', view: Home, layout: LayoutUser, role: [] },
    arts_book: { path: '/arts-book', view: ArtsBook, layout: LayoutUser, role: [] },
    about: { path: '/about', view: About, layout: LayoutUser, role: [] },

    // ACCESS
    authentication_admin: { path: '/authentication/admin', view: Login, layout: LayoutUser, roles: [] },
    maintenance: { path: '/maintenance', view: Maintenance, layout: LayoutUser, roles: [] },
}

let routes = {};
let defaultPath = '';

// Security
export const updateRouting = (authenticationReducer, loginPage, isMaintenance) => {
    let routesTmp = {};
    let urlOrigin = localStorage.getItem('urlOrigin');
    if (authenticationReducer && Object.entries(authenticationReducer).length > 0) { // Login
        for (let index in routing) {
            routesTmp[index] = routing[index];
        }
        delete routesTmp.authentication_admin;
        delete routesTmp.maintenance;

        if (authenticationReducer.defaultRoute && routesTmp[authenticationReducer.defaultRoute]) {
            defaultPath = routesTmp[authenticationReducer.defaultRoute].path;
        }
        else {
            if (Object.keys(routesTmp)[0]) {
                defaultPath = routesTmp[Object.keys(routesTmp)[0]].path
            }
            else { // Logout
                for (let index in routing) {
                    routesTmp[index] = routing[index];
                }
                if (!isMaintenance) delete routesTmp.maintenance;
                defaultPath = '/home';
            }
        }

        let routeGet = getRouteByPathnameOnRoutesTmp(routesTmp, urlOrigin);
        if (urlOrigin && Object.keys(routeGet).length !== 0) {
            defaultPath = urlOrigin;
            localStorage.removeItem('urlOrigin');
        }

    }
    else { // Logout
        console.log('ici')
        if (isMaintenance) {
            routesTmp.authentication_admin = routing['authentication_admin'];
            routesTmp.maintenance = routing['maintenance'];
            defaultPath = '/maintenance'
        } else {
            for (let index in routing) {
                routesTmp[index] = routing[index];
            }
            delete routesTmp.maintenance;
            defaultPath = loginPage.page === 'admin' ? '/admin' : '/home';
        }
    }

    routes = routesTmp;
}

export const getRoutes = () => {
    return routes;
};

// Dependency Security
export const getRoute = (id) => {
    let routes = getRoutes();
    let route = routes[id];
    return route ? { ...route, id: id } : {};
};

export const getPathDefault = () => {
    return defaultPath;
};

export const getRoutePathname = (id, params = []) => {
    let route = getRoute(id);
    let path = route.path ?? '/';

    for (let index in params) {
        let regex = new RegExp('/:' + index + '[?]{0,1}', 'gi')
        path = path.replace(regex, params[index] ? '/' + params[index] : '');
    }

    return path;
};

export const getRouteByPathname = (pathname) => {
    let routes = getRoutes();
    for (let index in routes) {
        if (routes[index].match && routes[index].match.test(pathname)) {
            return { ...routes[index], id: index };
        }
        else if (routes[index].path === pathname) {
            return { ...routes[index], id: index };
        }
    }

    return {};
};

export const getRouterLayout = () => {
    let routes = getRoutes();
    let routerLayout = {};
    for (let index in routes) {
        if (!routerLayout[routes[index].layout.name]) {
            routerLayout[routes[index].layout.name] = [];
        }
        routerLayout[routes[index].layout.name].push(routes[index])
    }

    return routerLayout;
};

const getRouteByPathnameOnRoutesTmp = (router, pathname) => {
    let routes = router;

    for (let index in routes) {
        if (routes[index].match && routes[index].match.test(pathname)) {
            return { ...routes[index], id: index };
        }
        else if (routes[index].path === pathname) {
            return { ...routes[index], id: index };
        }
    }
    return {};
};