import { makeStyles } from "@mui/styles";
import gsap from "gsap";
import React, { useEffect, useRef, useState } from "react";

const OrganicComponent = (props) => {
    const classes = useStyles();
    const organicRef = useRef(null);

    const paths = [
        "M 452.7 0 L 452.7 361.6 C 452.7 361.6 422.2 353.8 412.4 351 C 402.6 348.2 413.7 327.9 400.6 321.3 C 380.4 311.2 346.5 304.4 346.5 304.4 C 327.6 301.9 257.5 281.9 243.9 266.6 C 205.7 223.5 274.9 204.4 297.1 167.1 C 319.3 129.8 306.3 133 335.1 105.3 C 363.9 77.6 462.5 102.4 452.6 52.4 C 442.7 2.4 451.3 22.6 410.4 12.9 C 369.5 3.2 301 0 271.9 0 L 452.7 0 Z",
        //"M 452.7 0 L 452.7 361.6 C 419.9 354.7 408.9 350.5 349.3 346.4 C 305.2 343.4 373.3 327.9 360.2 321.4 C 340 311.3 301.4 306 301.4 306 C 249.3 299.7 220.2 283.9 206.6 268.6 C 168.4 225.5 285.5 209 297.1 167.1 C 302.3 148.3 244.6 147.6 271.4 117.9 C 304.9 80.9 470.3 100.2 452.7 52.4 C 440.9 20.4 364.2 56.7 328.8 18.1 C 318.7 7 293 5.7 271.9 0 L 452.7 0 Z",
        //"M 452.7 0 L 452.7 361.6 C 419.9 354.7 408.9 350.5 349.3 346.4 C 305.2 343.4 295.5 354.9 280.9 355.6 C 227.9 358.1 269.5 326.6 269.5 326.6 C 322.5 289 195.8 330.3 174.3 277.6 C 152.5 224.2 285.5 209 297.2 167.1 C 302.4 148.3 70.1 178.5 96.9 148.8 C 123.7 119.1 470.4 100.1 452.8 52.3 C 441 20.3 282.8 56.9 289.8 20 C 292.6 5.3 258.8 6.6 272 0 L 452.8 0 Z",
        //"M 452.7 0 L 452.7 361.6 C 423.9 339.2 383.5 332.4 349.3 346.4 C 308.3 363.1 277.1 378 262.5 378.7 C 209.5 381.2 248.6 346 269.5 326.6 C 303.6 294.7 112.3 350.1 90.8 297.4 C 69 244 103.5 241.9 115.1 200.1 C 120.3 181.3 69.9 178.6 96.8 148.9 C 123.7 119.2 349.7 136 332 88.2 C 320.2 56.2 282.6 57.1 289.6 20.1 C 292.4 5.4 258.6 6.7 271.8 0.1 L 452.6 0.1 Z",
        //"M 452.7 0 L 452.7 361.6 C 423.9 339.2 383.5 332.4 349.3 346.4 C 308.3 363.1 270 431.7 225.7 411 C 177.7 388.5 290.6 339.9 269.4 326.6 C 229.8 301.8 47.8 363.8 26.3 311.2 C 4.5 257.8 103.4 242 115 200.1 C 120.2 181.3 69.8 178.6 96.7 148.9 C 123.6 119.2 349.6 136 331.9 88.2 C 320.1 56.2 237.8 72.9 242.3 31.8 C 243.9 16.9 258.5 6.7 271.8 0.1 L 452.6 0.1 Z",
        "M 452.7 0 L 452.7 361.6 C 423.9 339.2 383.5 332.4 349.3 346.4 C 308.3 363.1 274.1 417.5 225.7 411 C 199.6 407.5 182.1 380.7 160.9 367.4 C 121.3 342.6 47.8 363.8 26.3 311.2 C 4.5 257.8 68.3 243.4 79.9 201.5 C 85.1 182.7 82.6 164.6 96.7 148.8 C 130 111.6 192.2 136.3 225.1 97.4 C 240.9 78.7 234.6 53.4 242.3 31.7 C 247.4 17.6 258.5 6.6 271.8 0 L 452.6 0 Z"
    ];

    const [currentPathIndex, setCurrentPathIndex] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);  // Suivi de l'animation en cours

    // Fonction pour interpoler deux chemins SVG
    const interpolatePaths = (path1, path2, progress) => {
        const parsePath = (path) =>
            path.match(/[a-zA-Z]|-?\d+(\.\d+)?/g); // Sépare commandes et coordonnées

        const path1Parts = parsePath(path1);
        const path2Parts = parsePath(path2);

        if (path1Parts.length !== path2Parts.length) {
            console.error("Les chemins doivent avoir le même nombre de commandes et de points.");
            return path1;
        }

        // Interpoler uniquement les parties numériques
        return path1Parts
            .map((part, index) => {
                if (isNaN(part)) {
                    return part; // Conserve les commandes
                }
                const num1 = parseFloat(part);
                const num2 = parseFloat(path2Parts[index]);
                return num1 + (num2 - num1) * progress;
            })
            .join(' ');
    };

    // Fonction d'animation
    const animatePath = (startPath, endPath, duration = 1000) => {
        const startTime = performance.now();

        function step(timestamp) {
            const elapsed = timestamp - startTime;
            const progress = Math.min(elapsed / duration, 1);

            const interpolatedPath = interpolatePaths(startPath, endPath, progress);
            if (organicRef.current) {
                organicRef.current.setAttribute('d', interpolatedPath);
            }

            if (progress < 1) {
                requestAnimationFrame(step); // Continue l'animation
            } else {
                setIsAnimating(false);  // Animation terminée
            }
        }

        requestAnimationFrame(step); // Démarre l'animation
    };

    useEffect(() => {
        if (paths.length > 1 && !isAnimating) {
            setIsAnimating(true);  // Animation commence

            // Transition entre le chemin actuel et le chemin suivant
            const nextPathIndex = props.isAnimeLoading
                ? Math.max(currentPathIndex - 1, 0)
                : Math.min(currentPathIndex + 1, paths.length - 1);

            animatePath(paths[currentPathIndex], paths[nextPathIndex], 500);
            setCurrentPathIndex(nextPathIndex);  // Met à jour l'index du chemin actuel
        }
    }, [props.isAnimeLoading, currentPathIndex, paths, isAnimating]);

    return (
        <>
            <svg className={classes.organic} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 452.7 434.4">
                <defs>
                    <pattern id="imagePattern" patternUnits="userSpaceOnUse" width="500" height="500">
                        <image href="/asset/home/6.png" x="0" y="0" width="500" height="500" />
                    </pattern>
                </defs>
                <path ref={organicRef} d={paths[0]} />
            </svg>
        </>
    );
};

const useStyles = makeStyles({
    organic: {
        background: "#302F3B",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: '100%',
        '& path': {
            fill: /*"url(#imagePattern)"*/"#FFF",
            //stroke: '#FFF',
            transform: 'translate(1px,-1px)'
        }
    }
});

export default OrganicComponent;
