import React, { useEffect, useRef } from "react";
import Box from '@mui/material/Box';
import { getRoutePathname } from "../../../Config/Router";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import { AuthenticationLogin } from "../../../Action/AuthenticationAction";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import { useDispatch, useSelector } from "react-redux";
import { LoginPage } from "../../../Action/LoginPageAction";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import Api from "../../../Api";
import Typewriter from "typewriter-effect/dist/core";
import { makeStyles } from '@mui/styles';
import Hero from "../Component/Hero";
import OrganicComponent from "../Component/OrganicComponent";

const Login = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const isAdmin = props.location.pathname.startsWith('/authentication/admin');
    const buttonRef = useRef(null);
    const formRef = useRef(null);
    const titleRef = useRef(null);

    const [loading, setLoading] = React.useState(false);

    // Login
    const [form, setForm] = React.useState({
        email: {
            name: 'email',
            label: 'Email',
            textHelper: 'Votre adresse email.',
            type: 'text',
            defaultValue: '',
            options: {
                validation: ['required', 'email']
            }
        },
        password: {
            name: 'password',
            label: 'Mot de passe',
            textHelper: 'Votre mot de passe.',
            type: 'text',
            defaultValue: '',
            options: {
                validation: ['required']
            }
        }
    });

    const handler = formHandlerInit(form, setForm);

    const login = () => {
        if (handler.checkError() < 1) {
            handler.setFormLoading(true);
            setLoading(true);

            let data = handler.getData();

            Api.post({
                route: 'authentication_login',
                data: { ...data, isAdmin: isAdmin }
            }, (response) => {
                if (response.status === 200) {

                    /*window.dataLayer.push({
                        'event': 'login',
                        'user_email': data.email,
                        'user_ID': response.data.id
                    })*/

                    setTimeout(() => {
                        dispatch(AuthenticationLogin(response.data))
                        dispatch(LoginPage(isAdmin ? 'admin' : 'visitor'))
                    }, 1200);
                }
                else if (response.status === 403) {
                    handler.setFormLoading(false);
                    setLoading(false);

                    handler.setError('email', ' ');
                    handler.setError('password', ' ');
                    dispatch(
                        SnackbarOpen({
                            text: 'Votre email ou votre mot de passe est incorrect.',
                            variant: 'error',
                        })
                    );
                }
                else {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    }

    React.useEffect(() => {
        if (!titleRef.current) return;

        const typewriter = new Typewriter(titleRef.current, {
            loop: false,
            delay: 100,
            deleteSpeed: 20,
        });

        typewriter
            .typeString("Accès à l'espace administrateur.")
            .pauseFor(2500)
            .start();

        return () => {
            typewriter.stop();
        };
    }, []);

    const animeBlock = (block, timerActive, timerRemove) => {
        if (!block) return;

        if (props.isAnimeLoading) {
            setTimeout(() => {
                // Animation de disparition
                block.style.transition = 'all 0.5s ease-in-out';
                block.style.opacity = '0';
                block.style.transform = 'translate(-100%, 0)';
            }, timerRemove);
        } else {
            setTimeout(() => {
                // Animation d'apparition
                block.style.transition = 'all 0.5s ease-in-out';
                block.style.opacity = '1';
                block.style.transform = 'translate(0, 0)';
            }, timerActive);
        }
    }

    useEffect(() => {
        const button = buttonRef.current;
        animeBlock(button, 1400, 500);
        const form = formRef.current;
        animeBlock(form, 1000, 300);
        const title = titleRef.current;
        animeBlock(title, 1000, 300);
    }, [props.isAnimeLoading]);

    return (
        <Box style={{ display: 'grid', gridTemplateColumns: '50% 50%', color: '#FFF', height: '100%', alignItems: 'center' }}>
            <Box className={classes.containerForm}>
                <div style={{ maxWidth: 500, margin: 'auto' }}>
                    {/*<AdminAccessAnimation isAnimeLoading={props.isAnimeLoading} />*/}
                    <h1 ref={titleRef} style={{ margin: 0, fontSize: 60, height: 133 }}></h1>
                    <Box ref={formRef} className={`${classes.form}`}>
                        <TextFieldComponent name={'email'} handler={handler} onSubmit={login} noSpace={true} />
                        <TextFieldComponent name={'password'} handler={handler} onSubmit={login} password noSpace={true} />
                    </Box>
                    <ButtonComponent ref={buttonRef} type="submit" label={'Se connecter'} className={`${classes.button}`} style={{ margin: '20px 0 0 0' }} onClick={login} disabled={loading} />
                </div>
            </Box>
            <Box style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                <Hero {...props} />
                {/*<OrganicComponent {...props}/>*/}
            </Box>
        </Box >
    )
}

const AdminAccessAnimation = ({ isAnimeLoading }) => {
    const titleRef = useRef(null);
    const typewriterRef = useRef(null); // Référence pour l'instance de Typewriter
    const currentAction = useRef("writing"); // Suivi de l'état de l'animation ("writing" ou "deleting")

    useEffect(() => {
        if (!titleRef.current) return;

        if (!typewriterRef.current) {
            // Initialisation de Typewriter seulement si c'est la première fois
            typewriterRef.current = new Typewriter(titleRef.current, {
                loop: false,
                delay: 80,
                deleteSpeed: 2,
            });

            // Lancer l'animation d'écriture au début
            typewriterRef.current
                .typeString("Accès à l'espace administrateur.")
                .pauseFor(2500)
                .start();

            currentAction.current = "writing"; // L'état initial est l'écriture
        }

        // Si `isAnimeLoading` passe à `true` et que l'animation en cours est "writing", alors on stoppe et on efface
        if (isAnimeLoading && currentAction.current === "writing") {
            typewriterRef.current.stop(); // Stopper l'écriture
            typewriterRef.current.deleteAll().start(); // Effacer le texte immédiatement
            currentAction.current = "deleting"; // Maintenant, l'animation en cours est "deleting"
        }

        // Si `isAnimeLoading` passe à `false` et que l'animation en cours est "deleting", alors on stoppe et on écrit
        if (!isAnimeLoading && currentAction.current === "deleting") {
            typewriterRef.current.stop(); // Stopper l'effacement
            typewriterRef.current
                .typeString("Accès à l'espace administrateur.") // Reprendre l'écriture
                .pauseFor(2500)
                .start();
            currentAction.current = "writing"; // Maintenant, l'animation en cours est "writing"
        }

        return () => {
            typewriterRef.current?.stop(); // Nettoyer l'instance de typewriter lorsque le composant est démonté
        };
    }, [isAnimeLoading]);

    return <h1 ref={titleRef} style={{ margin: 0, fontSize: 60, height: 133 }}></h1>;
};

const useStyles = makeStyles({
    button: {
        margin: '20px 0 0 0',
        opacity: 0,
        transform: 'translate(-100%,0)'
    },
    form: {
        display: 'flex',
        gap: 10,
        flexDirection: 'column',
        opacity: 0,
        transform: 'translate(-100%,0)'
    },
    containerForm: {
        '& input, label': {
            color: '#FFF !important'
        },
        '& .MuiFormHelperText-root': {
            color: '#FFF'
        },
        '& .MuiInputBase-root': {
            '&::after': {
                borderBottom: '2px solid #FFF !important'
            },
            '&::before': {
                borderBottom: '1px solid #FFF !important',
                '&::hover': {
                    borderBottom: '2px solid #FFF !important'
                }
            },
        }
    }
});
export default Login;