import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import AdsClickOutlinedIcon from '@mui/icons-material/AdsClickOutlined';
import { getRoutePathname } from '../../Config/Router';
import { LoadingView } from '../../Action/LoadingViewAction';
import { useDispatch } from 'react-redux';
import Typewriter from "typewriter-effect/dist/core";
import React, { useEffect, useRef } from 'react';

function Home(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const titleRef = React.useRef(null);
    const aboutRef = useRef(null);
    const artsbookRef = useRef(null);
    const descriptionRef = useRef(null);
    const artsbookButtonRef = useRef(null);

    React.useEffect(() => {
        if (!titleRef.current) return;

        const typewriter = new Typewriter(titleRef.current, {
            loop: false,
            delay: 100,
            deleteSpeed: 20,
        });

        typewriter
            .typeString("BIENVENUE DANS UN MONDE OÙ LE CODE DEVIENT DE L\'ART.")
            /*.pauseFor(2500)
            .deleteAll()
            .typeString("CODE ET CRÉATIVITÉ : EXPLOREZ MON UNIVERS EN LIGNE")*/
            .pauseFor(2500)
            .start();

        return () => {
            typewriter.stop();
        };
    }, []);

    const animeBlock = (block, unite1, unite2, uniteRemove, timerActive, timerRemove) => {
        if (!block) return;

        if (props.isAnimeLoading) {
            setTimeout(() => {
                // Animation de disparition
                block.style.transition = 'all 0.5s ease-in-out';
                block.style.opacity = '0';
                block.style.transform = `translate(${unite1}%, ${unite2}%)`;
            }, timerRemove);
        } else {
            setTimeout(() => {
                // Animation d'apparition
                block.style.transition = 'all 0.5s ease-in-out';
                block.style.opacity = '1';
                block.style.transform = `translate(${uniteRemove}%, ${unite2}%)`;
            }, timerActive);
        }
    }

    useEffect(() => {
        animeBlock(aboutRef.current, 100, 0, 0, 1400, 300);
        animeBlock(artsbookRef.current, -100, 0, 0, 1000, 300);
        animeBlock(artsbookButtonRef.current, -400, -50, -75, 1000, 300);
        animeBlock(descriptionRef.current, 100, 0, 0, 1000, 300);
        animeBlock(titleRef.current, -100, 0, 0, 1000, 300);
    }, [props.isAnimeLoading]);

    /*useEffect(() => {
        const card = aboutRef.current;
        const handleMouseMove = (e) => {
            const rect = card.getBoundingClientRect();
            const centerX = rect.left + rect.width / 2;
            const centerY = rect.top + rect.height / 2;

            const mouseX = e.clientX;
            const mouseY = e.clientY;

            const deltaX = (mouseX - centerX) / (rect.width / 2);
            const deltaY = (mouseY - centerY) / (rect.height / 2);

            // Appliquer les rotations en fonction de la position de la souris
            card.style.transform = `rotateX(${deltaY * 20}deg) rotateY(${deltaX * -20}deg)`;
        };

        const handleMouseLeave = () => {
            // Réinitialiser la transformation lorsque la souris quitte la carte
            card.style.transform = 'rotateY(0deg) rotateX(0deg)';
        };

        card.addEventListener('mousemove', handleMouseMove);
        card.addEventListener('mouseleave', handleMouseLeave);

        return () => {
            card.removeEventListener('mousemove', handleMouseMove);
            card.removeEventListener('mouseleave', handleMouseLeave);
        };
    }, []);*/

    return (
        <Box style={{ height: '100%' }}>
            <Box style={{ height: '100%', display: 'grid', gridTemplateRows: 'max-content auto', width: '80%', margin: 'auto' }}>
                <TitleAnime />
                <Box className={classes.main}>
                    <Box className={'title-root'} style={{ gridArea: 'title', position: 'relative', }}>
                        <h1 ref={titleRef} style={{ position: 'absolute', fontSize: 75, margin: 0, color: '#FFF' }}></h1>
                    </Box>
                    <Box className={'about-root'} style={{ zIndex: 1 }}>
                        <Box ref={aboutRef} onClick={() => props.history.push(getRoutePathname('about'))}>
                            <Box>
                                <AdsClickOutlinedIcon />
                            </Box>
                            <Box style={{ background: '#302F3B', position: 'relative' }}>
                                <p style={{ margin: 0, transform: 'rotate(90deg) translate(50%,60px)', width: 'max-content', position: 'absolute', color: '#FFF', top: 15, left: 0, fontSize: 20 }}>À PROPOS DE MOI</p>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={'artsbook-root'} onClick={() => { props.history.push(getRoutePathname('arts_book')) }}>
                        <Box ref={artsbookRef}>
                            <h1 style={{ textAlign: 'center' }}>
                                <span style={{ display: 'block' }}>Arts Book</span>
                                <span style={{ fontSize: 15, display: 'block' }}>lorem ipsum</span>
                            </h1>
                            <img src={'./asset/home/6.png'} style={{ position: 'absolute', width: '100%', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', }} />
                            <AdsClickOutlinedIcon />
                        </Box>
                        <Box style={{ height: '100%', position: 'relative', zIndex: 10 }}>
                            <Box ref={artsbookButtonRef} >
                                <p style={{ fontSize: 15, margin: 'auto auto 15px auto', color: '#FFF', textAlign: 'center' }}>ACCÈDER</p>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={'description-root'} ref={descriptionRef} >
                        <Box style={{ height: '60%', margin: 'auto 0 0', width: '100%', display: 'grid', gridTemplateRows: 'max-content auto', }}>
                            <p style={{ border: '3px solid #FFF', width: 'fit-content', margin: 0, borderBottom: 'none', color: '#FFF', padding: '9px 25px', borderRadius: '15px 15px 0 0' }}>Bienvenue sur Arts Game !</p>
                            <Box style={{ background: '#FFF', height: '100%', borderRadius: '0 20px 20px 20px', margin: 'auto 0 0', width: '100%' }}>
                                <Box style={{ position: 'relative', height: '100%', }}>
                                    <p style={{ position: 'absolute', padding: '30px 40px', margin: 0 }}>Explorez l’intersection entre l’art numérique et le développement web.
                                        Découvrez mes créations et mon parcours professionnel. Bonne visite !</p>
                                </Box>
                                <Box></Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box >
    );
}

const TitleAnime = (props) => {
    const classes = useStyles();
    return (
        <Box className={classes.titleOne}>
            <hr /><hr /><hr /><hr />
            <h1>ArtsGame</h1>
            <hr /><hr /><hr /><hr />
        </Box>
    )
}
const useStyles = makeStyles({
    titleOne: {
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: 'auto max-content max-content max-content max-content max-content max-content max-content auto',
        '& hr': {
            margin: 0,
            border: 'none',
            borderRadius: 10,
            height: 6,
            background: '#FFF',
            animationTimingFunction: 'ease'
        },
        '& hr:nth-of-type(4), hr:nth-of-type(5)': {
            width: 40,
        },
        '& hr:nth-of-type(4)': {
            opacity: 0,
            borderRadius: '15px 0 0 15px ',
            animation: '$slide4 .7s',
            animationDelay: '1s',
            animationFillMode: 'forwards',
            transform: 'translate(100%,0)'
        },
        '& hr:nth-of-type(5)': {
            animation: '$slide5 .7s',
            opacity: 0,
            borderRadius: '0 15px 15px 0',
            animationDelay: '1s',
            animationFillMode: 'forwards',
            transform: 'translate(-100%,0)'
        },
        '& hr:nth-of-type(3), hr:nth-of-type(6)': {
            width: 30,
            margin: '0 15px',
        },
        '& hr:nth-of-type(3)': {
            animation: '$slide3 .7s',
            opacity: 0,
            animationDelay: '0.8s',
            animationFillMode: 'forwards',
            transform: 'translate(300%,0)'
        },
        '& hr:nth-of-type(6)': {
            animation: '$slide6 .7s',
            opacity: 0,
            animationDelay: '0.8s',
            animationFillMode: 'forwards',
            transform: 'translate(-300%,0)'
        },
        '& hr:nth-of-type(2), hr:nth-of-type(7)': {
            width: 30,
        },
        '& hr:nth-of-type(2)': {
            animation: '$slide2 .7s',
            opacity: 0,
            margin: '0 20px 0 200px',
            animationDelay: '0.5s',
            transform: 'translate(500%,0)',
            animationFillMode: 'forwards'
        },
        '& hr:nth-of-type(7)': {
            animation: '$slide7 .7s',
            opacity: 0,
            margin: '0 200px 0 20px',
            animationDelay: '0.5s',
            transform: 'translate(-500%,0)',
            animationFillMode: 'forwards'
        },
        '& hr:nth-of-type(1), hr:nth-of-type(8)': {
            width: '100%',
        },
        '& hr:nth-of-type(1)': {
            animation: '$slide1 .7s',
            opacity: 0,
            animationDelay: '.3s',
            animationFillMode: 'forwards',
            transform: 'translate(400%,0)'
        },
        '& hr:nth-of-type(8)': {
            animation: '$slide8 .7s',
            opacity: 0,
            animationDelay: '.3s',
            animationFillMode: 'forwards',
            transform: 'translate(-400%,0)'
        },
        '& h1': {
            margin: 0,
            color: '#FFF',
            background: '#302F3B',
            padding: '5px 40px 10px 40px',
            fontSize: 25,
            borderRadius: '0 0 15px 15px',
            border: '3px solid',
            borderTop: 'none',
            zIndex: 1
        },
        animation: '$fade-in-out .7s',
    },
    '@keyframes slide1': {
        '0%': {
            opacity: 0,
            transform: 'translate(400%,0)'
        },
        '100%': {
            opacity: 1,
            transform: 'translate(0%,0)'
        },
    },
    '@keyframes slide2': {
        '0%': {
            opacity: 0,
            transform: 'translate(500%,0)'
        },
        '100%': {
            opacity: 1,
            transform: 'translate(0%,0)'
        },
    },
    '@keyframes slide3': {
        '0%': {
            opacity: 0,
            transform: 'translate(300%,0)'
        },
        '100%': {
            opacity: 1,
            transform: 'translate(0%,0)'
        },
    },
    '@keyframes slide4': {
        '0%': {
            opacity: 0,
            transform: 'translate(100%,0)'
        },
        '100%': {
            opacity: 1,
            transform: 'translate(0%,0)'
        },
    },
    '@keyframes slide5': {
        '0%': {
            opacity: 0,
            transform: 'translate(-100%,0)'
        },
        '100%': {
            opacity: 1,
            transform: 'translate(0%,0)'
        },
    },
    '@keyframes slide6': {
        '0%': {
            opacity: 0,
            transform: 'translate(-300%,0)'
        },
        '100%': {
            opacity: 1,
            transform: 'translate(0%,0)'
        },
    },
    '@keyframes slide7': {
        '0%': {
            opacity: 0,
            transform: 'translate(-500%,0)'
        },
        '100%': {
            opacity: 1,
            transform: 'translate(0%,0)'
        },
    },
    '@keyframes slide8': {
        '0%': {
            opacity: 0,
            transform: 'translate(-400%,0)'
        },
        '100%': {
            opacity: 1,
            transform: 'translate(0%,0)'
        },
    },
    '@keyframes fade-in-out': {
        '0%': {
            opacity: 0,
            transform: 'translate(0px,-25px)'
        },
        '50%': {
            opacity: .5,
        },
        '100%': {
            opacity: 1,
        },
    },
    main: {
        display: 'grid',
        gap: 30,
        padding: '40px 0',
        gridTemplateAreas: ` 'title title title about'
                'title title title about'
                'artsbook artsbook description description'
                'artsbook artsbook description description'`,
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        gridTemplateTows: 'auto auto 1fr 1fr',
        '& .title-root': {
            '& h1': {
                animation: 'textclip 2s linear infinite'
            }
        },
        '& .about-root': {
            gridArea: 'about',
            '& >div': {
                cursor: 'pointer',
                background: '#FFF',
                display: 'grid',
                gridTemplateColumns: 'auto 50px',
                height: '120%',
                borderRadius: 20,
                overflow: 'hidden',
                border: '2px solid #FFF',
                position: 'relative',
                transform: 'translate(100%, 0)',
                opacity: 0,
                '& >div:nth-of-type(1)': {
                    '& svg': {
                        position: 'absolute',
                        zIndex: 1,
                        background: '#FFF',
                        padding: '10px 20px',
                        bottom: '0%',
                        borderRadius: '0px 18px 0 0',
                        color: '#302F3B',
                        '& path': {
                            transform: 'rotate(90deg) translate(0px,-25px)'
                        }
                    },
                }
            }
        },
        '& .artsbook-root': {
            gridArea: 'artsbook',
            display: 'grid',
            gridTemplateColumns: 'auto 50px',
            '& >div:nth-of-type(1)': {
                cursor: 'pointer',
                background: '#FFF',
                position: 'relative',
                height: '100%',
                borderRadius: 20,
                overflow: 'hidden',
                border: '2px solid #FFF',
                transform: 'translate(-100%, 0)',
                opacity: 0,
                '& h1': {
                    position: 'absolute',
                    transform: 'translate(-50%,-50%)',
                    left: '50%',
                    top: '50%',
                    margin: 0,
                    zIndex: 2,
                    color: '#FFF'
                },
                '& svg': {
                    position: 'absolute',
                    zIndex: 1,
                    background: '#FFF',
                    padding: '10px 20px',
                    bottom: '0%',
                    borderRadius: '0px 18px 0 0',
                    color: '#302F3B',
                    transition: 'font-size 0.4s ease, opacity 0.4s ease',
                    '& path': {
                        transform: 'rotate(90deg) translate(0px,-25px)'
                    }
                },
                '&::before': {
                    content: "' '",
                    background: '#302F3B91',
                    height: '100%',
                    width: '100%',
                    position: 'absolute',
                    zIndex: 1
                }
            },
            '& >div:nth-of-type(2)': {
                '& >div': {
                    background: '#302F3B',
                    height: 150,
                    borderRadius: 20,
                    position: 'absolute',
                    width: 110,
                    top: '50%',
                    left: '50%',
                    border: '2px solid #FFF',
                    zIndex: 10,
                    display: 'flex',
                    cursor: 'pointer',
                    transform: 'translate(-400%,-50%)',
                    transition: 'left 0.4s ease',
                    opacity: 0,
                }
            },
            '&:hover >div:nth-of-type(2) >div': {
                left: '-10% !important'
            },
            '&:hover >div:nth-of-type(1) svg': {
                fontSize: 30
            }
        },
        '& .description-root': {
            gridArea: 'description',
            display: 'flex',
            opacity: 0,
            transform: 'translate(100%, 0)',
        }
    },
})
export default Home;