import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useSelector } from "react-redux";

let timeoutClose = false;

const LoadingViewComponent = (props) => {
    const classes = useStyles();
    const loadingViewReducer = useSelector(state => state.LoadingViewReducer);
    const [loadingView, setLoadingView] = React.useState(false);
    const [isOpen, setOpen] = React.useState(true);

    React.useEffect(() => {
        setLoadingView({ ...loadingViewReducer });
        //setOpen(loadingViewReducer.isOpen);
        setOpen(props.isAnimeLoading);
    }, [loadingViewReducer, props.isAnimeLoading]);
    return (
        <Box className={isOpen ? classes.LoadingActiveView : classes.LoadingView} style={{ position: 'absolute', transition: 'all 0.5s', zIndex: 1000 }}></Box>
    );
}
const useStyles = makeStyles({
    LoadingView: {
        width: 100,
        height: 100,
        borderRadius: 100,
        background: '#515171',
        top: '50%',
        left: '50%',
        transform: 'translate(100%,-10%)',
    },
    LoadingActiveView: {
        width: '100%',
        height: '100%',
        transform: 'translate(0%,-0%)',
        background: '#302F3B',
        top: 0,
        left: 0,
        borderRadius: 0,
    }
})
export default LoadingViewComponent;