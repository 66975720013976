import React, { useState, useEffect, useRef } from "react";
import * as basicScroll from 'basicscroll';

function AnimeSvgComponent(props = {
    classStyle: '',
    svg: '',
}) {
    const parallax = () => {
        document.querySelectorAll('.' + props.classStyle + ' path').forEach((elem) => {
            basicScroll.create({
                elem: elem,
                from: 'bottom-bottom',
                to: 'top-top',
                direct: true,
                props: {
                    '--stroke-dashoffset': {
                        from: '4000px',
                        to: '0px'
                    }
                },
            }).start()
        })
    }
    React.useEffect(() => {
        parallax();
    })
    return (
        <props.svg classStyle={props.classStyle} />
    )
}
export default AnimeSvgComponent;